<template>
  <div>
    <down-full-up-popup
      :title="$t('CustomerService.Inquiry1')"
      @close="$emit('close')"
    >
      <div slot="body">
        <div class="select_box type05">
          <div class="box">
            <button
              type="button"
              class="select btn_pop_open"
              @click="isShowSelectType = true"
            >
              {{
                form.type
                  ? handelChageType(form.type)
                  : $t('CustomerService.AlertInquiryType')
              }}
            </button>
          </div>
        </div>
        <!--문의하기 작성 전 버튼-->
        <div v-if="isType" class="inner ptb14 border_top">
          <template v-if="selectedProduct === null">
            <button
              type="button"
              class="white_btn width100 font2 btn_pop_open"
              @click="isShowMyOrders = true"
            >
              {{ $t('CustomerService.OrderLookUp') }}
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              class="white_btn width100 font2 btn_pop_open"
              @click="handeleReset"
            >
              {{ $t('CustomerService.Reset') }}
            </button>
            <div class="review_con disflex">
              <image-bg-src
                :src="
                  selectedProduct.cit_file_1
                    ? selectedProduct.cit_file_1
                    : form.cit_file_1
                "
                class="review_pro"
                style="width: 60px; height: 80px"
              />
              <div class="detail_pro">
                <h6 class="fontEn font2">
                  {{
                    selectedProduct.den_name
                      ? selectedProduct.den_name
                      : form.cit_name
                  }}
                </h6>
                <p
                  v-for="(cdeTitle, key) in handelReturnEdit(
                    selectedProduct.cde_title,
                  )"
                  :key="key"
                  style="font-size: 12px; color: #a8a8a8"
                >
                  {{ cdeTitle }}
                </p>
                <p class="font1">
                  ₩
                  {{
                    selectedProduct.cit_sale_price
                      ? selectedProduct.cit_sale_price
                      : form.cit_sale_price
                  }}
                </p>
              </div>
            </div>
          </template>
        </div>
        <div class="inner p04">
          <ul class="wrap_flex write_warp m0">
            <li>
              <label for="inquiry_tit" class="write_tit">
                {{ $t('CustomerService.InquiryTitle') }}</label
              >
              <input
                id="inquiry_tit"
                v-model="form.title"
                type="text"
                class="input_enter"
                :maxLength="50"
                :placeholder="$t('CustomerService.AlertTitle')"
              />
              <p>
                <strong>{{ form.title.length }}</strong
                >/50
              </p>
            </li>
            <li>
              <label for="inquiry_con" class="write_tit">
                {{ $t('CustomerService.InquiryContent') }}
              </label>
              <textarea
                v-model="form.content"
                class="input_enter"
                :placeholder="$t('Review.AlertContent')"
                :maxLength="1000"
              ></textarea>
              <p>
                <strong>{{ form.content.length }}</strong
                >/1,000
              </p>
            </li>
            <li class="pb14">
              <h6 class="write_tit">{{ $t('CustomerService.AlertAttach') }}</h6>

              <ul class="pt_add_box type2 p0">
                <li
                  v-for="(url, idx) in form.urls"
                  :key="idx"
                  :class="url ? 'added_img' : ''"
                  :style="url ? `background-image: url(${url})` : ''"
                  @click="handleClickImage(url, idx)"
                >
                  <label v-if="url === ''" for="files">
                    <input
                      id="files"
                      type="file"
                      accept="image/*"
                      multiple
                      @change="handleChangeFile"
                    />
                  </label>
                </li>
              </ul>
              <p>
                {{ $t('CustomerService.InquiryAttach') }}
              </p>
            </li>
          </ul>
          <ul class="wrap_flex basic_info">
            <li class="basic_tit mb10">
              {{ $t('CustomerService.AnswerReceive') }}
            </li>
            <li class="disflex input_chk_box">
              <input id="sms" v-model="form.reception[0]" type="checkbox" />
              <label for="sms" class="color37 font2">SMS</label>
            </li>
            <li class="disflex input_chk_box">
              <input id="email" v-model="form.reception[1]" type="checkbox" />
              <label for="email" class="color37 font2">E-Mail</label>
            </li>
          </ul>
          <div class="bg_f7 mt14">
            <div class="font2">
              {{ $t('CustomerService.AnswerAlert') }}
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="button"
          class="black_btn font4 pos_fixed0 btn_pop_open"
          style="width: 100%"
          @click="handleClickSubmit"
        >
          {{ $t('Review.Registration') }}
        </button>
      </template>
    </down-full-up-popup>
    <my-order-list-popup
      v-if="isShowMyOrders"
      @close="isShowMyOrders = false"
      @confirm="handleConfirmOrders"
    />
    <sort-popup
      v-if="isShowSelectType"
      :items="items"
      :edit="edit"
      :title="$t('CustomerService.AlertInquiryType')"
      height="150px"
      @close="isShowSelectType = false"
      @select="handleSelectType"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import SortPopup from '../../../components/popups/SortPopup.vue';
import useToast from '../../../composables/dialogs/useToast';
import MyOrderListPopup from './MyOrderListPopup.vue';
import ImageBgSrc from '../../../components/ui/ImageBlock/ImageBgSrc.vue';
import useConfirm from '../../../composables/dialogs/useConfirm';

export default {
  components: { DownFullUpPopup, SortPopup, MyOrderListPopup, ImageBgSrc },
  props: ['edit', 'editClose'],
  setup(props, context) {
    const items = [
      context.root.$t('CustomerService.Return'),
      context.root.$t('CustomerService.Exchange'),
      context.root.$t('CustomerService.OrderCancel'),
      context.root.$t('CustomerService.Refund'),
      context.root.$t('CustomerService.Shipping'),
      context.root.$t('CustomerService.ProductInquiry'),
      context.root.$t('CustomerService.Point'),
      context.root.$t('CustomerService.UserInfo'),
      context.root.$t('CustomerService.Order'),
      context.root.$t('CustomerService.Digital'),
      context.root.$t('CustomerService.Ect'),
    ].map(val => ({ key: val, label: val }));
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();
    const mem_id = localStorage.getItem('mem_id');

    const state = reactive({
      isShowSelectType: false,
      isShowMyOrders: false,
      form: {
        files: [],
        type: null,
        title: '',
        content: '',
        reception: [],
        urls: new Array(3).fill(''),
        cod_ids: [],
        cpq_files: [],
        cde_title: [],
      },
      getValue: {
        img_file1: '',
        img_file2: '',
        img_file3: '',
      },
      cqa_id: null,
      selectedProduct: null,
      edit: null,
      idx: 0,
      isType: false,
    });

    context.emit('editClose', false);
    const validate = () => {
      // if(state.form.cde_title === null) {
      //   return  false;
      // }

      if (props.edit) {
        if (state.getValue.cqa_type === '') {
          addMsg(context.root.$t('CustomerService.AlertTypeSelect'));
          return false;
        }

        if (state.getValue.order_id === '') {
          addMsg(context.root.$t('CustomerService.AlertInquiryAdd'));
          return false;
        }

        if (state.getValue.cqa_title === '') {
          addMsg(context.root.$t('CustomerService.AlertTitle'));
          return false;
        }

        if (state.getValue.cqa_content === '') {
          addMsg(context.root.$t('CustomerService.AlertInquiryContent'));
          return false;
        }
      }

      if (state.getValue.cqa_type === '') {
        addMsg(context.root.$t('CustomerService.AlertTypeSelect'));
        return false;
      }

      if (state.getValue.cqa_title === '') {
        addMsg(context.root.$t('CustomerService.AlertTitle'));
        return false;
      }

      if (state.getValue.cqa_content === '') {
        addMsg(context.root.$t('CustomerService.AlertInquiryContent'));
        return false;
      }

      return true;
    };

    const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    const fileValidate = files => {
      let isImage = true;

      [...files].forEach(file => {
        if (isImage) {
          isImage = imageTypes.indexOf(file['type'].toLowerCase()) > -1;
        }
      });

      if (isImage === false) {
        addMsg(context.root.$t('Review.AlertFormat'));
        return false;
      }
      if (props.edit) {
        console.log('----');
      } else {
        if (state.form.files.length + files.length > 3) {
          addMsg(context.root.$t('CustomerService.AlertMax3'));
          return false;
        }
      }

      return true;
    };
    isEdit();
    function isEdit() {
      if (props.edit) {
        if (state.form.cde_title === null) {
          console.log('RIRI');
        }
        state.form.type = props.edit.cqa_type ? props.edit.cqa_type : '';
        state.form.title = props.edit.cqa_title ? props.edit.cqa_title : '';
        state.form.content = props.edit.cqa_content
          ? props.edit.cqa_content
          : '';
        state.form.reception[0] = props.edit.cqa_sms
          ? JSON.parse(props.edit.cqa_sms)
          : false;
        state.form.reception[1] = props.edit.cqa_email
          ? JSON.parse(props.edit.cqa_email)
          : false;

        state.form.order_id = props.edit.order_id ? props.edit.order_id : '';
        state.form.cod_id = props.edit.cod_id ? props.edit.cod_id : '';
        state.form.cit_file_1 = props.edit.cit_file_1
          ? props.edit.cit_file_1
          : '';
        state.form.cit_name = props.edit.cit_name ? props.edit.cit_name : '';
        state.form.cde_title = props.edit.cde_title ? props.edit.cde_title : '';
        state.form.den_id = props.edit.den_id ? props.edit.den_id : '';
        state.form.cit_price = props.edit.cit_price ? props.edit.cit_price : '';
        state.form.cit_sale_price = props.edit.cit_sale_price
          ? props.edit.cit_sale_price
          : '';

        state.form.urls[0] = props.edit.cqa_file1
          ? process.env.VUE_APP_FILE_URL + props.edit.cqa_file1
          : '';
        state.form.urls[1] = props.edit.cqa_file2
          ? process.env.VUE_APP_FILE_URL + props.edit.cqa_file2
          : '';
        state.form.urls[2] = props.edit.cqa_file3
          ? process.env.VUE_APP_FILE_URL + props.edit.cqa_file3
          : '';
        state.form.cde_title = props.edit.cde_title ? props.edit.cde_title : '';
        state.form.cpq_files = [
          props.edit.cqa_file1,
          props.edit.cqa_file2,
          props.edit.cqa_file3,
        ];

        state.form.cqa_id = props.edit.cqa_id ? props.edit.cqa_id : '';
        if (
          props.edit.cqa_type === context.root.$t('CustomerService.Return') ||
          props.edit.cqa_type === context.root.$t('CustomerService.Exchange') ||
          props.edit.cqa_type ===
            context.root.$t('CustomerService.OrderCancel') ||
          props.edit.cqa_type === context.root.$t('CustomerService.Refund') ||
          props.edit.cqa_type === context.root.$t('CustomerService.Shipping')
        ) {
          state.selectedProduct = '';
        }

        validate();
      }
    }
    return {
      ...toRefs(state),
      items,

      handleSelectType(item) {
        state.isShowSelectType = false;
        state.form.type = item.key;
      },
      handelChageType(type) {
        if (
          type === context.root.$t('CustomerService.ProductInquiry') ||
          type === context.root.$t('CustomerService.Point') ||
          type === context.root.$t('CustomerService.UserInfo') ||
          type === context.root.$t('CustomerService.Order') ||
          type === context.root.$t('CustomerService.Digital') ||
          type === context.root.$t('CustomerService.Ect')
        ) {
          state.selectedProduct = null;
          state.isType = false;
        } else {
          state.isType = true;
        }

        return type;
      },
      handelReturnEdit(cde_title) {
        let cdeTitle = [];
        if (cde_title) {
          cdeTitle.push(cde_title);
        } else {
          cdeTitle.push(state.form.cde_title);
        }
        return cdeTitle;
      },
      handeleReset() {
        state.selectedProduct = null;
        if (props.edit) {
          state.form.order_id = null;
        }
      },
      handleChangeFile(e) {
        if (props.edit) {
          const files = [...e.target.files];
          state.form.files = [...state.form.files, ...files];
          if (fileValidate(files)) {
            files.forEach(() => {
              state.form.urls[state.idx] = URL.createObjectURL(files[0]);
            });
            state.getValue[`img_file${state.idx + 1}`] = files[0];
            state.form.urls[state.idx] = URL.createObjectURL(files[0]);
            document
              .querySelectorAll('.pt_add_box li')
              [state.idx].classList.add('added_img');
            document.querySelectorAll('.pt_add_box li')[
              state.idx
            ].style.backgroundImage =
              'url(' + URL.createObjectURL(files[0]) + ')';
          }
        } else {
          const files = [...e.target.files];
          if (fileValidate(files)) {
            const urls = state.form.urls.filter(url => url !== '');

            files.forEach(file => {
              urls.push(URL.createObjectURL(file));
            });
            state.form.files = [...state.form.files, ...files];

            state.form.files.forEach((file, idx) => {
              state.getValue[`img_file${idx + 1}`] = file;
            });

            for (let i = urls.length; i < 3; i++) {
              urls.push('');
            }
            state.form.urls = urls;
          } else {
            e.target.value = '';
          }
        }
      },
      handleClickImage(url, index) {
        state.idx = index;

        if (props.edit) {
          const imageList = document.querySelectorAll('.pt_add_box li');
          // 수정일때
          imageList[state.idx].classList.remove('added_img');
          imageList[state.idx].style.backgroundImage = '';

          state.form.cpq_files[state.idx] = '';
          state.form.files = [...state.form.files, ''];

          if (state.idx > -1) {
            const urls = state.form.urls;
            urls[state.idx] = '';
            urls.push('');

            const files = state.form.files;

            state.form.urls = urls;
            state.form.files = files;
            state.form.urls.pop();
            state.form.urls;
          }
        } else {
          // 수정이 아닐떄
          if (url === '') return;
          const idx = state.form.urls.findIndex(value => value === url);
          if (idx > -1) {
            const urls = [...state.form.urls];
            urls.splice(idx, 1);
            urls.push('');

            const files = [...state.form.files];
            files.splice(idx, 1);

            state.form.urls = urls;
            state.form.files = files;
          }
        }
      },

      handleClickSubmit() {
        if (
          state.form.type === context.root.$t('CustomerService.Return') ||
          state.form.type === context.root.$t('CustomerService.Exchange') ||
          state.form.type === context.root.$t('CustomerService.OrderCancel') ||
          state.form.type === context.root.$t('CustomerService.Refund') ||
          state.form.type === context.root.$t('CustomerService.Shipping')
        ) {
          if (props.edit) {
            if (
              state.form.order_id === null ||
              state.selectedProduct === null
            ) {
              addMsg(context.root.$t('CustomerService.AlertInquiryList'));
              return false;
            }
          } else {
            if (state.selectedProduct === null) {
              addMsg(context.root.$t('CustomerService.AlertInquiryList'));
              return false;
            }
          }
        }

        state.getValue.cqa_type = state.form.type ? state.form.type : '';
        state.getValue.url = state.form.urls;
        state.getValue.files = state.form.files;
        state.getValue.cqa_title = state.form.title;
        state.getValue.cqa_content = state.form.content;
        state.getValue.sms = state.form.reception[0]
          ? state.form.reception[0]
          : false;
        state.getValue.email = state.form.reception[1]
          ? state.form.reception[1]
          : false;
        state.getValue.mem_id = mem_id;
        if (state.isType) {
          state.getValue.order_id = state.selectedProduct
            ? state.selectedProduct.order_id
            : state.form.order_id
            ? state.form.order_id
            : '';
          state.getValue.cod_id = state.selectedProduct
            ? state.selectedProduct.cod_id
            : state.form.cod_id
            ? state.form.cod_id
            : '';
          state.getValue.cit_file_1 = state.selectedProduct
            ? state.selectedProduct.cit_file_1
            : state.form.cit_file_1
            ? state.form.cit_file_1
            : '';
          state.getValue.cit_name = state.selectedProduct
            ? state.selectedProduct.cit_name
            : state.form.cit_name
            ? state.form.cit_name
            : '';
          state.getValue.cde_title = state.selectedProduct
            ? state.selectedProduct.cde_title
            : state.form.cde_title
            ? state.form.cde_title
            : '';
          state.getValue.den_id = state.selectedProduct
            ? state.selectedProduct.den_id
            : state.form.den_id
            ? state.form.den_id
            : '';
          state.getValue.cit_price = state.selectedProduct
            ? state.selectedProduct.cit_price
            : state.form.cit_price
            ? state.form.cit_price
            : '';
          state.getValue.cit_sale_price = state.selectedProduct
            ? state.selectedProduct.cit_sale_price
            : state.form.cit_sale_price
            ? state.form.cit_sale_price
            : '';
          state.getValue.cde_title = state.form.cde_title
            ? state.form.cde_title
            : state.selectedProduct.cde_title;
        }

        state.getValue.cqa_id = state.form.cqa_id ? state.form.cqa_id : '';
        state.getValue.cit_id = state.getValue.cit_id
          ? state.getValue.cit_id
          : '';
        state.getValue.cpq_files = state.form.cpq_files
          ? state.form.cpq_files
          : '';
        if (props.edit) {
          // 수정
          showConfirm(context.root.$t('CustomerService.AlertEdit'), () => {
            if (validate()) {
              context.emit('update', state.getValue);
            }
          });
        } else {
          // 등록
          showConfirm(context.root.$t('CustomerService.AlertConfirm'), () => {
            if (validate()) {
              context.emit('submit', state.getValue);
            }
          });
        }
      },
      handleConfirmOrders(product) {
        state.selectedProduct = product;
        state.isShowMyOrders = false;
      },
    };
  },
};
</script>
<style></style>
