import moment from 'moment';

export default {
  dttmToNumber(str) {
    return Number(str.replace(/[:]|[-]|[\s]/g, ''));
  },
  getNowString(formatStr) {
    const now = moment();
    return now.format(formatStr);
  },
};
