<template>
  <div class="wrap">
    <back-title-basket :title="$t('CustomerService.MyInquiry')" />
    <div class="layout_body">
      <section class="header_tab">
        <ul class="disflex tab_page">
          <li
            :class="`width33 ${type === 'items' ? 'on' : ''}`"
            @click="type = 'items'"
          >
            <a
              >{{ $t('CustomerService.AllInquiry') }}
              {{ store.items.length }}</a
            >
          </li>
          <li
            :class="`width33 ${type === 'answers' ? 'on' : ''}`"
            @click="type = 'answers'"
          >
            <a>
              {{ $t('CustomerService.AnswerComplete') }}
              {{ store.answers.length }}</a
            >
          </li>
          <li
            :class="`width33 ${type === 'noAnswers' ? 'on' : ''}`"
            @click="type = 'noAnswers'"
          >
            <a
              >{{ $t('CustomerService.AnswerWait') }}
              {{ store.noAnswers.length }}</a
            >
          </li>
        </ul>
        <div class="search_box">
          <button
            type="button"
            class="white_btn width100 font2 btn_pop_open"
            @click="isShowForm = true"
          >
            {{ $t('CustomerService.InquiryButton') }}
          </button>
        </div>
      </section>
      <div>
        <section class="tab_content">
          <div class="drop_down_wrap type2">
            <ul v-if="store[type]">
              <li v-for="(item, idx) in store[type]" :key="idx">
                <dropdown-notice-item
                  :isEdit="true"
                  :isTag="true"
                  :item="item"
                  :isAnser="item.is_answer !== '' ? item.is_answer : null"
                  @isUpdate="hadleUpdate"
                >
                  <DateVue :date="item.cqa_dtm" />
                </dropdown-notice-item>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>

    <sild-footer :isToggleNav="true" />

    <inquiry-form-popup
      v-if="isShowForm"
      @close="isShowForm = false"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import DropdownNoticeItem from '../../components/ui/DropdownNoticeItem/DropdownNoticeItem.vue';
import InquiryFormPopup from './popups/InquiryFormPopup.vue';
import ServiceAPI from '@/apis/ServiceAPI';
import DateVue from '@Views/service-centers/DateVue';
import useUserInfo from '@Composables/user/useUserInfo';
import useToast from '@Composables/dialogs/useToast';

export default {
  components: {
    DateVue,
    SildFooter,
    BackTitleBasket,
    DropdownNoticeItem,
    InquiryFormPopup,
  },
  props: ['update', 'edit'],
  setup(_, context) {
    const { addMsg } = useToast();
    const state = reactive({
      type: 'items',
      store: {
        items: [],
        answers: [],
        noAnswers: [],
        imgUrl: [],
      },
      isShowForm: false,
    });
    const { info } = useUserInfo();
    const loadData = async () => {
      const { data } = await ServiceAPI.noticeInquiryList({
        mem_id: info?.mem_id,
      });
      state.store.items = data;
      state.store.noAnswers = state.store.items.filter(item_list => {
        return item_list.is_answer === 'n';
      });
      state.store.answers = state.store.items.filter(item_list => {
        return item_list.is_answer === 'y';
      });
    };
    loadData();
    return {
      ...toRefs(state),
      loadData,
      async handleSubmit(responseData) {
        const { data } = await ServiceAPI.noticeInquiry(responseData);
        state.isShowForm = false;
        if (data.result) {
          loadData();
          addMsg(context.root.$t('CustomerService.InquiryButton'));
        } else {
          addMsg(context.root.$t('CustomerService.AlertError'));
        }
      },
      hadleUpdate() {
        loadData();
      },
      handleInquiryImageUrlUpdate() {
        state.store.imgUrl;
      },
    };
  },
};
</script>

<style></style>
