import { axios } from "@/plugins/axios";

export default {
  getMyOrderItems(params) {
    return axios.post("order/getMyOrderItems", params);
  },
  getOrderDetail(params) {
    return axios.post("order/getOrderDetail", params);
  },

  getMyOrderStatistics(params) {
    return axios.post("order/getMyOrderStatistics", params);
  },
  getOrderNumber() {
    return axios.post("order/getOrderNumber");
  },

  getOrderComplateInfo(params) {
    return axios.post("order/getOrderComplateInfo", params);
  },
  getChangeRequest(params) {
    return axios.post("order/changeRequest", params);
  },
  RetrunRequest(params) {
    return axios.post("/order/returnRequest", params);
  },

  //상세

  getReturnRequest(params) {
    return axios.post("/order/getReturnRequest", params);
  },
  getChangeDetailRequest(params) {
    return axios.post("/order/getChangeRequest", params);
  },
  //반품 취소
  getReturnCancel(params) {
    return axios.post("/order/returnCancel", params);
  },
  //교환 취소
  getChangesCancel(params) {
    return axios.post("/order/changeCancel", params);
  },
  //취소, 반품, 교환 리스트
  getMyOrderItemsByStatus(params) {
    return axios.post("/order/getMyOrderItemsByStatus", params);
  },
  //취소, 반품, 교환 요청체크
  checkOrderDetailQty(params) {
    return axios.post("/order/checkOrderDetailQty", params);
  },
  //취소, 반품, 교환 총 개수
  getMyOrderItemsTotal(params) {
    return axios.post("/order/getMyOrderItemsTotalByStatus", params);
  },
};
