<template>
  <select-box
    class="type01"
    :items="banks"
    :placeholder="$t('Input.BankSelect')"
    @input="handleInput"
  />
</template>

<script>
import SelectBox from '../SelectBox/SelectBox.vue';

export default {
  components: { SelectBox },
  setup(_, context) {
    const banks = [
      context.root.$t('Input.Bank1'),
      context.root.$t('Input.Bank2'),
      context.root.$t('Input.Bank3'),
      context.root.$t('Input.Bank4'),
      context.root.$t('Input.Bank5'),
      context.root.$t('Input.Bank6'),
    ];
    return {
      banks: banks.map(val => ({ key: val, label: val })),

      handleInput(item) {
        context.emit('input', item);
      },
    };
  },
};
</script>

<style></style>
