<template>
  <div class="return_form">
    <down-full-up-popup className="p0" title="반품신청" @close="$emit('close')">
      <div slot="body" class="group_list">
        <div class="inner ptb14 flex_col">
          <span class="font2 colora8"> {{ order.ins_dtm.substr(0, 10) }}</span>
          <strong
            >{{ $t('OrderInquiry.OrderNumber') }} {{ order.order_id }}</strong
          >
        </div>
        <div class="inner p04">
          <div class="group_list">
            <div class="pro_desc_box type4 disflex">
              <router-link :to="`/brand/detail/${item.den_id}`">
                <image-bg-src
                  :src="
                    orderItem.img_url ? orderItem.img_url : item.seller_picture
                  "
                  class="profile_img"
                />
                <div class="profile_user new_title">
                  <h3 class="name fontEn">{{ item.den_name }}</h3>
                  <p class="desc">{{ item.provider_summary }}</p>
                </div>
              </router-link>
              <template v-if="device_type === 'pc'">
                <button
                  class="radi_btnG flex_right"
                  type="button"
                  @click="isShowBrandDetail = true"
                >
                  {{ $t('Button.SellerInfo') }}
                </button>
              </template>

              <template v-else>
                <a :href="`tel:${item.cs_no}`" class="radi_btnG flex_right">
                  {{ $t('Button.MakeAPhone') }}
                </a>
              </template>
            </div>
            <div class="group_mem">
              <h6 class="font4 fontWb mt10 filterDiv deposit_ing">
                {{ OrderStatus[item.status] }}
              </h6>
              <div class="order_con ver2 disflex" style="min-height: 150px">
                <template v-if="item.is_gubun !== '일반연동'">
                  <image-bg-src
                    :baseURL="item.cit_file_1"
                    class="prod_img left"
                  />
                </template>
                <template v-else>
                  <image-bg-src :src="item.cit_file_1" class="prod_img left" />
                </template>

                <div class="right">
                  <span class="ellipsis">
                    {{ item.cit_summary }}
                  </span>
                  <h2>{{ item.cit_name }}</h2>
                  <span class="mt14">{{ item.cde_title }}</span>
                  <span>{{ item.qty }}개</span>
                  <span class="flex_top fontNum">
                    ₩ {{ (priceTotal * temp_qty).numberFormat() }}
                  </span>
                </div>
              </div>
              <div class="detail_fee border_top9">
                <ul class="wrap_flex">
                  <li>{{ $t('OrderInquiry.PaymentInfo') }}</li>
                  <li>{{ item.product_code }}</li>
                </ul>
              </div>
              <div class="table_wrap ver2">
                <div>
                  <table class="table_box">
                    <colgroup>
                      <col style="width: 27%" />
                      <col style="width: 73%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>{{ $t('Button.OrderPrice') }}</th>
                        <td>
                          ₩
                          {{ Number(item.order.total_price).numberFormat() }}
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('Basket.DeliveryFee') }}</th>
                        <!--                        <td>₩ {{ order.delivery_price.numberFormat() }}</td>-->
                        <td>
                          ₩
                          {{
                            orderItem.order.delivery_price === 0
                              ? ''
                              : Number(
                                  orderItem.order.delivery_price
                                ).numberFormat()
                          }}
                        </td>
                      </tr>

                      <tr>
                        <th>{{ $t('OrderInquiry.PaymentType') }}</th>
                        <td>{{ getPayMethodName(item.order.payMethod) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Button.CancellationQuantity') }}</th>
                        <td class="evidence disflex">
                          <number-counter
                            v-model="temp_qty"
                            :max="orderQty.max"
                            :min="orderQty.min + 1"
                            :value="temp_qty"
                          />
                          <!--                          <div class="flex_right">-->
                          <!--                            <button-->
                          <!--                              class="black_btn width30"-->
                          <!--                              @click="handleQtySave"-->
                          <!--                            >-->
                          <!--                              {{ $t('Button.Quantity') }}-->
                          <!--                            </button>-->
                          <!--                          </div>-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="border_top mt20 reReason_layout">
                <h6 class="allPage_tit">{{ $t('Exchange.ReturnReason') }}</h6>
                <div class="inner p04">
                  <ul class="wrap_flex basic_info">
                    <li class="disflex input_chk_box">
                      <label class="color37 font2">
                        <input
                          class="check_box"
                          type="checkbox"
                          value="고객단순변심"
                          @click="getCheckValue"
                        />
                        {{ $t('Button.SimpleChange') }}
                      </label>
                    </li>
                    <li class="disflex input_chk_box">
                      <label class="color37 font2">
                        <input
                          class="check_box"
                          type="checkbox"
                          value="선택오류"
                          @click="getCheckValue"
                        />
                        {{ $t('Button.SelectionError') }}
                      </label>
                    </li>
                    <li class="disflex input_chk_box">
                      <label class="color37 font2">
                        <input
                          class="check_box"
                          type="checkbox"
                          value="상품불량"
                          @click="getCheckValue"
                        />
                        {{ $t('Button.Defected') }}
                      </label>
                    </li>

                    <li class="disflex input_chk_box">
                      <label class="color37 font2">
                        <input
                          class="check_box"
                          type="checkbox"
                          value="오배송"
                          @click="getCheckValue"
                        />
                        {{ $t('Button.Wrong') }}
                      </label>
                    </li>
                    <li class="disflex input_chk_box">
                      <label class="color37 font2">
                        <input
                          class="check_box"
                          type="checkbox"
                          value="상품상이"
                          @click="getCheckValue"
                        />
                        {{ $t('Button.Difference') }}
                      </label>
                    </li>
                    <li class="disflex input_chk_box">
                      <label class="color37 font2">
                        <input
                          class="check_box"
                          type="checkbox"
                          value="기타(사유입력)"
                          @click="getCheckValue"
                        />
                        {{ $t('Button.EctEnter') }}
                      </label>
                    </li>
                    <li
                      :style="`${isEtc ? 'display:block' : 'display:none;'}`"
                      class="ect_reason"
                    >
                      <label class="basic_tit" for="cancel9"></label>
                      <input
                        id="cancel9"
                        v-model="reasonForReturn"
                        :placeholder="$t('Withdraw.ReturnReason')"
                        class="deep_type"
                        type="text"
                      />
                    </li>
                    <li class="write_warp mt14">
                      <h6 class="write_tit">{{ $t('Review.AlertAttach') }}</h6>
                      <ul class="pt_add_box p0">
                        <li
                          v-for="(url, idx) in urls"
                          :key="idx"
                          :class="url ? 'added_img' : ''"
                          :style="url ? `background-image: url(${url})` : ''"
                          @click="handleClickImage(url)"
                        >
                          <label v-if="url === ''" for="files">
                            <input
                              id="files"
                              accept="image/*"
                              multiple
                              type="file"
                              @change="handleChangeFile"
                            />
                          </label>
                        </li>
                      </ul>
                      <p class="">
                        {{ $t('Exchange.ExchangeDescription1') }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="border_top mt30">
                <h6 class="allPage_tit">
                  {{ $t('Exchange.ExchangeDescription2') }}
                </h6>
                <div class="inner">
                  <div class="guide_phrases">
                    <p
                      v-html="
                        $t('Exchange.ExchangeDescription3', {
                          fee:
                            reasonForReturn === '오배송' ||
                            reasonForReturn === '상품불량' ||
                            reasonForReturn === '상품상이'
                              ? 0
                              : (
                                  Number(orderItem.order.delivery_price) +
                                  Number(ShopReturnFee)
                                ).numberFormat(),
                        })
                      "
                    ></p>

                    <p
                      v-html="
                        $t(
                          'Exchange.ExchangeSumFee',

                          {
                            initial:
                              reasonForReturn === '오배송' ||
                              reasonForReturn === '상품불량' ||
                              reasonForReturn === '상품상이'
                                ? 0
                                : Number(orderItem.order.delivery_price),
                            return:
                              reasonForReturn === '오배송' ||
                              reasonForReturn === '상품불량' ||
                              reasonForReturn === '상품상이'
                                ? 0
                                : ShopReturnFee,
                          }
                        )
                      "
                    >
                      <span
                        class="font2"
                        v-html="$t('Exchange.ExchangeDescription4')"
                      ></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="border_top mt30">
                <h6 class="allPage_tit">
                  {{ $t('Exchange.ExchangeDescription5') }}
                </h6>
                <div class="inner">
                  <div class="shop_user">
                    <ul class="wrap_flex basic_info type02">
                      <li>
                        <label class="basic_tit" for="byer_n">
                          {{ $t('Exchange.ExchangeDescription6') }}
                        </label>
                        <input
                          v-model="recipient_name"
                          :placeholder="$t('Join.InputName')"
                          class="deep_type"
                          type="text"
                        />
                      </li>
                      <li>
                        <label class="font4" for="phoneNum">{{
                          $t('Delivery.PhoneNumber')
                        }}</label>
                        <div class="phone_input disflex">
                          <select-box
                            v-model="phone[0]"
                            :items="phoneNumbers"
                            class="type01"
                            style="height: 40px"
                          />
                          <input-number
                            v-model="phone[1]"
                            :maxLength="4"
                            class="btn_group"
                          />
                          <input-number
                            v-model="phone[2]"
                            :maxLength="4"
                            class="btn_group"
                          />
                        </div>
                      </li>
                      <li>
                        <div class="userinfo wrap_flex p0">
                          <label class="basic_tit" for="">{{
                            $t('Delivery.ZipCode')
                          }}</label>
                          <input
                            v-model="recipient_zip"
                            class="btn_group deep_type back_color7"
                            readonly
                            type="text"
                          />
                          <button class="black_btn" @click="handleClickJuso">
                            {{ $t('Delivery.ZipCode') }}
                          </button>
                        </div>
                      </li>
                      <li>
                        <label class="basic_tit" for="byer_tel">{{
                          $t('Delivery.EditAddress')
                        }}</label>
                        <input
                          v-model="recipient_addr1"
                          class="deep_type back_color7"
                          readonly
                          type="text"
                        />
                      </li>
                      <li>
                        <label class="basic_tit" for="buis_num">{{
                          $t('Delivery.DetailAddress')
                        }}</label>
                        <input
                          id="buis_num"
                          v-model="recipient_addr2"
                          class="deep_type"
                          type="text"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--환불 정보-->
              <div class="border_top mt20">
                <h6 class="allPage_tit">{{ $t('CancelList.RefundInfo') }}</h6>
                <div class="inner p04">
                  <div class="table_wrap">
                    <div>
                      <table class="table_box">
                        <colgroup>
                          <col style="width: 27%" />
                          <col style="width: 73%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>{{ $t('CancelList.ReturnAmount') }}</th>
                            <td>
                              ₩
                              {{ priceTotal * temp_qty }}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <th>{{ $t('Basket.DeliveryFee') }}</th>
                            <!--                            orderItem.order.delivery_price-->
                            <td>
                              ₩
                              {{
                                (reasonForReturn === '오배송' ||
                                reasonForReturn === '상품불량' ||
                                reasonForReturn === '상품상이'
                                  ? 0
                                  : Number(orderItem.order.delivery_price) +
                                    Number(ShopReturnFee)
                                ).numberFormat()
                              }}
                            </td>
                          </tr>
                          <tr v-if="orderItem.order.use_coupon !== '0'">
                            <th>할인및차감금액</th>
                            <td>
                              ₩ {{ orderItem.order.use_coupon }} (쿠폰사용)
                            </td>
                          </tr>
                          <tr v-if="orderItem.order.use_point !== '0'">
                            <th>{{ $t('CancelList.DeductionAmount') }}</th>
                            <td>
                              {{ orderItem.order.use_point }}
                              {{ $t('Button.UsePoint') }}
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t('CancelList.RefundInfo') }}</th>
                            <td>
                              <p class="font2">{{ payMehodStatus }}</p>
                              <p
                                v-if="orderItem.order.use_point !== '0'"
                                class="font2"
                              >
                                {{ $t('Mypage.Point') }}
                                {{ orderItem.order.use_point }}P
                              </p>
                              <p
                                v-if="orderItem.order.use_coupon !== '0'"
                                class="font2"
                              >
                                쿠폰 {{ orderItem.order.use_coupon }}P
                              </p>
                            </td>
                          </tr>

                          <tr class="refund_pri">
                            <th>{{ $t('CancelList.RefundAmount') }}</th>
                            <td>
                              ₩
                              {{
                                (
                                  Number(priceTotal) * temp_qty +
                                  Number(returnFee)
                                ).numberFormat()
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="colora8 font2 mt10">
                      {{ $t('Exchange.ExchangeDescription7') }}
                    </p>
                  </div>
                  <ul class="order_tip cancel_tip">
                    <li class="agree_block disflex input_chk_box">
                      <input
                        id="refundNum"
                        v-model="isAgreeCheck"
                        type="checkbox"
                      /><label class="chkbox" for="refundNum">{{
                        $t('CancelList.RefundDescription1')
                      }}</label>
                    </li>
                    <li>{{ $t('CancelList.RefundDescription2') }}</li>
                    <li>{{ $t('CancelList.RefundDescription3') }}</li>
                    <li v-html="$t('CancelList.RefundDescription4')"></li>
                  </ul>
                </div>
              </div>
              <div
                v-if="item.order.payMethod !== 'CARD'"
                class="border_top mt30"
              >
                <h6 class="allPage_tit">
                  {{ $t('CancelList.RefundDescription6') }}
                </h6>
                <div class="inner refund_number">
                  <div>
                    <ul class="wrap_flex basic_info type02">
                      <li class="mt20">
                        <h6 class="basic_tit">
                          {{ $t('CancelList.RefundDescription6') }}
                        </h6>
                        <div class="option_box">
                          <!-- 은행선택 -->
                          <bank-selector
                            @input="(val) => (formData.bankName = val)"
                          />
                        </div>
                      </li>
                      <li>
                        <label class="basic_tit ver2" for="">{{
                          $t('CancelList.AccountName')
                        }}</label>
                        <!-- 예금주 -->

                        <input
                          v-model="formData.bankSelector"
                          class="deep_type"
                          readonly
                          type="text"
                        />
                      </li>
                      <li>
                        <label class="basic_tit ver2" for="">{{
                          $t('CancelList.Account')
                        }}</label>
                        <!-- 계좌번호 -->
                        <input
                          v-model="formData.accountNumber"
                          :placeholder="$t('CancelList.AlertAccount')"
                          class="deep_type"
                          type="text"
                        />
                      </li>
                      <li>
                        <p
                          class="colora8 font2"
                          v-html="$t('CancelList.RefundDescription7')"
                        ></p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="inner p04">
                <ul class="order_tip cancel_tip mt20">
                  <li class="agree_block align_top input_chk_box">
                    <input
                      id="refundNum2"
                      v-model="formData.ExChangeCheck"
                      type="checkbox"
                    /><label
                      class="chkbox"
                      for="refundNum2"
                      v-html="$t('Exchange.ExchangeDescription8')"
                    ></label>
                  </li>
                  <li class="pt10">
                    {{ $t('Exchange.ExchangeDescription9') }}
                  </li>
                  <li>{{ $t('Exchange.ExchangeDescription10') }}</li>
                  <li>{{ $t('Exchange.ExchangeDescription11') }}</li>
                  <li>{{ $t('Exchange.ExchangeDescription12') }}</li>
                  <li>{{ $t('Exchange.ExchangeDescription13') }}</li>
                  <li>{{ $t('Exchange.ExchangeDescription14') }}</li>
                  <li>{{ $t('Exchange.ExchangeDescription15') }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <button type="button" @click="handleCancel">
          {{ $t('Exchange.CancelButton') }}
        </button>
        <button type="reset" @click="handleReturnRequest">
          {{ $t('Exchange.ReturnRegister') }}
        </button>
      </template>
    </down-full-up-popup>
    <provider-info
      v-if="isShowBrandDetail"
      :den_id="item.den_id"
      @close="isShowBrandDetail = false"
    />
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue-demi';
import ProviderInfo from './ProviderInfo.vue';
import ImageBgSrc from '../../ImageBlock/ImageBgSrc.vue';
import OrderStatus from '../../../../consts/OrderStatus';
import { checkPlatform } from '../../../../utils/Buy';
import DownFullUpPopup from '../../../popups/DownFullUpPopup.vue';
import NumberCounter from '../../../Inputs/NumberCounter/NumberCounter.vue';
import {
  lockBodyClassnameHidden,
  removeBodyOverflowHidden,
  unlockBodyClassnameHidden,
} from '../../../../utils/CommonUtils';
import BankSelector from '../../../Inputs/BankSelector/BankSelector.vue';
import InputNumber from '../../../Inputs/InputNumber/InputNumber.vue';
import SelectBox from '../../../Inputs/SelectBox/SelectBox.vue';
import useToast from '../../../../composables/dialogs/useToast';
import OrderAPI from '@/apis/OrderAPI';
import useConfirm from '@Composables/dialogs/useConfirm';
import useAlert from '@Composables/dialogs/useAlert';
import useUserInfo from '@Composables/user/useUserInfo';
import BrandAPI from '@/apis/BrandAPI';

export default {
  components: {
    ProviderInfo,
    ImageBgSrc,
    DownFullUpPopup,
    NumberCounter,
    BankSelector,
    InputNumber,
    SelectBox,
  },
  props: ['item', 'order'],
  setup(props, context) {
    lockBodyClassnameHidden();
    const { showAlert } = useAlert();
    const { info } = useUserInfo();
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();
    console.log('info', info);
    const order = props.item.order;

    const state = reactive({
      files: [],
      urls: new Array(5).fill(''),
      qty: 1,
      temp_qty: 0,
      isShowBrandDetail: false,
      phone: ['', '', ''],
      total_price: 0,
      recipient_name: order.recipient_name,
      recipient_phone: order.recipient_phone,
      recipient_zip: order.recipient_zip,
      recipient_addr1: order.recipient_addr1,
      recipient_addr2: order.recipient_addr2,
      isEtc: false,
      orderItem: props.item,
      reasonForReturn: '',
      payMehodStatus: '',
      returnFee: 0,
      formData: {
        bankSelector: info?.mem_username,
        accountNumber: '',
        bankName: '',
        bankCode: '',
        ExChangeCheck: '',
      },
      isAgreeCheck: false,
      ShopReturnFee: 0,
      isCitSalePrice: 0,
      orderQty: {
        min: 0,
        max: 0,
      },
      storeDeliveryFee: 0,
    });

    //총 반품배송비 :반품배송비 + 기존 배송비
    const returnFee = () => {
      state.reasonForReturn === '오배송' ||
      state.reasonForReturn === '상품불량' ||
      state.reasonForReturn === '상품상이'
        ? (state.returnFee = 0)
        : (state.returnFee =
            Number(state.orderItem.order.delivery_price) +
            Number(state.ShopReturnFee));
    };

    watch(
      () => state.reasonForReturn,
      () => {
        returnFee();
      }
    );

    watch(
      () => state.temp_qty,
      (newValue) => {
        state.temp_qty = newValue;
        console.log(state.temp_qty);
      }
    );
    const totalDiscount = computed(() => {
      return (
        Number(props.item.use_coupon_value * state.temp_qty) +
        Number(props.item.use_grade_value * state.temp_qty) +
        Number(props.item.use_point_value * state.temp_qty) +
        Number(props.item.use_coupon_gap)
      );
    });
    watch(
      () => totalDiscount,
      (newValue) => {
        totalDiscount.value = newValue;
      }
    );
    console.log(totalDiscount);
    const priceTotal = computed(() => {
      const values =
        Number(props.item.cit_sale_price) * state.temp_qty -
        Number(totalDiscount.value);

      return values;
    });

    console.log('$$%%%%%%%%%%%%%%%%%%%%%%%%%');
    console.log(props.item);
    console.log('$$%%%%%%%%%%%%%%%%%%%%%%%%%');

    //총 환불금액 :주문금액 - 반품배송비 + 기존 배송비
    const total = computed(() => {
      const benefit =
        state.orderItem.order.use_coupon + state.orderItem.order.use_point;
      const product = props.item.order.total_price;
      // - state.orderItem.order.delivery_price;

      return Math.ceil(
        product / props.item.qty - Number(state.returnFee) - benefit
      );
    });
    watch(
      () => total,
      (newValue) => {
        console.log(newValue);
        // returnFee();
      }
    );
    console.log(props.item, props.order);
    const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (props.item) {
      if (props.item.order.payMethod === 'CARD') {
        state.payMehodStatus = '신용카드';
      } else if (
        props.item.order.payMethod === 'VBANK' ||
        props.item.order.payMethod === 'BANK'
      ) {
        state.payMehodStatus = '계좌';
      }
    }
    state.phone[0] = state.orderItem.order.recipient_phone.substring(0, 3);
    state.phone[1] = state.orderItem.order.recipient_phone.substring(3, 7);
    state.phone[2] = state.orderItem.order.recipient_phone.substring(7, 13);

    const getCheckValue = (e) => {
      let checkBox = document.querySelectorAll('.check_box');
      checkBox.forEach((el) => {
        el.checked = false;
      });
      e.target.checked = true;
      state.isEtc = false;
      if (e.target.value === '기타(사유입력)') {
        state.isEtc = true;
        state.reasonForReturn = '';
      } else {
        state.reasonForReturn = e.target.value;
      }
    };
    const fileValidate = (files) => {
      let isImage = true;

      [...files].forEach((file) => {
        if (isImage) {
          isImage = imageTypes.indexOf(file['type'].toLowerCase()) > -1;
        }
      });

      if (isImage === false) {
        addMsg(context.root.$t('Review.AlertFormat'));
        return false;
      }

      if (state.files.length + files.length > 5) {
        addMsg(context.root.$t('Review.AlertMax'));
        return false;
      }

      return true;
    };
    const handleCheckOrderQty = async () => {
      const { data } = await OrderAPI.checkOrderDetailQty({
        cod_id: props.item.cod_id,
      });
      state.temp_qty = data.data.possible_qty;
      if (data.data.possible_qty === 0) {
        showAlert(context.root.$t('Exchange.ReturnRequestHasBeenMade'));
        context.emit('close');
      } else {
        state.orderQty.min = data.data.refund_qty;
        state.orderQty.max = data.data.possible_qty;
      }
    };
    const handleBrandRetrunFee = async () => {
      const { data } = await BrandAPI.getBrandReturnFee({
        den_id: props.item.den_id,
        cod_id: props.item.cod_id,
      });
      console.log('@@@@@@@@@@@@@########################');
      console.log(data);
      console.log('@@@@@@@@@@@@@########################');
      if (data.result) {
        state.ShopReturnFee = data.data.return_delivery_fee;
        state.isCitSalePrice = data.data.cit_sale_price;
        state.storeDeliveryFee = data.data.return_delivery_fee;
      }
    };
    handleBrandRetrunFee();
    handleCheckOrderQty();
    const handleSubmitReturnRequest = async () => {
      await OrderAPI.RetrunRequest({
        mem_id: state.formData.mem_id,
        order_id: state.formData.order_id,
        cod_id: state.formData.cod_id,
        return_ea: state.formData.return_ea,
        cancel_reason: state.formData.cancel_reason,
        cancel_memo: state.formData.cancel_memo,
        recipient_name: state.formData.recipient_name,
        recipient_zip: state.formData.recipient_zip,
        recipient_phone: `${state.phone[0]}-${state.phone[1]}-${state.phone[2]}`,
        recipient_addr1: state.formData.recipient_addr1,
        recipient_addr2: state.formData.recipient_addr2,
        files: state.formData.files,
        bank_code: state.formData.bankCode,
        bank_name: state.formData.bankName,
        bank_owner: state.formData.bankSelector,
        bank_num: state.formData.accountNumber,
        img_file1: state.formData.img_file1,
        img_file2: state.formData.img_file2,
        img_file3: state.formData.img_file3,
        img_file4: state.formData.img_file4,
        img_file5: state.formData.img_file5,
      })
        .then((data) => {
          console.log('--------------------');
          console.log(data);
          console.log('--------------------');
          if (data.data.result_code === '9999') {
            showAlert(data.data.result_msg);
          } else {
            showAlert(context.root.$t('Exchange.AlertComplete'));
            context.root.$router.push('/mypage');
          }
          context.emit('IsRefundFeeOK', true);
        })
        .catch((error) => {
          console.log(error);
          showAlert(context.root.$t('Exchange.AlertError'));
          context.root.$router.push('/mypage');
        });
    };

    return {
      phoneNumbers,
      showAlert,
      OrderStatus,
      total,
      totalDiscount,
      priceTotal,
      returnFee,
      getCheckValue,
      ...toRefs(state),
      device_type: checkPlatform(window.navigator.userAgent),
      getPayMethodName(type) {
        switch (type) {
          case 'KAKAO':
            return context.root.$t('OrderInquiry.KakaoPay');
          case 'NAVER':
            return context.root.$t('OrderInquiry.NaverPay');
          case 'CARD':
            return context.root.$t('OrderInquiry.Card');
          case 'VBANK':
            return context.root.$t('OrderInquiry.VirtualAccount');
          case 'BANK':
            return context.root.$t('OrderInquiry.BankTransfer');
          default:
            return '';
        }
      },
      handleClickJuso() {
        new window.daum.Postcode({
          oncomplete: function (data) {
            state.recipient_zip = data.zonecode;
            state.recipient_addr1 = data.roadAddress;
          },
        }).open();
      },
      handleChangeFile(e) {
        const files = [...e.target.files];

        if (fileValidate(files)) {
          const urls = state.urls.filter((url) => url !== '');

          files.forEach((file) => {
            urls.push(URL.createObjectURL(file));
          });

          for (let i = urls.length; i < 5; i++) {
            urls.push('');
          }

          state.urls = urls;
          state.files = [...state.files, ...e.target.files];
        } else {
          e.target.value = '';
        }
      },
      handleClickImage(url) {
        if (url === '') return;

        const idx = state.urls.findIndex((value) => value === url);

        if (idx > -1) {
          const urls = [...state.urls];
          urls.splice(idx, 1);
          urls.push('');

          const files = [...state.files];
          files.splice(idx, 1);

          state.urls = urls;
          state.files = files;
        }
      },

      handleReturnRequest() {
        state.files.forEach((file, idx) => {
          state.formData[`img_file${idx + 1}`] = file;
        });

        state.formData.urls = state.urls;
        state.formData.mem_id = localStorage.getItem('mem_id');
        state.formData.order_id = state.orderItem.order.order_id;
        state.formData.cod_id = state.orderItem.cod_id;
        state.formData.return_ea = state.temp_qty;
        state.formData.cancel_reason = state.isEtc
          ? '기타(사유입력)'
          : state.reasonForReturn;
        state.formData.cancel_memo = state.isEtc ? state.reasonForReturn : '';
        state.formData.recipient_name = state.recipient_name;
        state.formData.recipient_zip = state.recipient_zip;
        state.formData.recipient_phone = state.orderItem.order.recipient_phone;
        state.formData.files = state.files;
        state.formData.recipient_addr1 = state.recipient_addr1;
        state.formData.recipient_addr2 = state.recipient_addr2;
        console.log('@@@@@@@@');
        console.log(state.formData);
        console.log('@@@@@@@@');
        if (!state.formData.cancel_reason) {
          showAlert(context.root.$t('Exchange.ExchangeCancelReason'));
          return;
        }
        if (!state.formData.recipient_name) {
          showAlert(context.root.$t('Exchange.ExchangeRecipinetName'));
          return;
        }
        if (!state.formData.recipient_zip) {
          showAlert(context.root.$t('Exchange.ExchangeRecipientZip'));
          return;
        }
        if (!state.formData.recipient_phone) {
          showAlert(context.root.$t('Exchange.ExchangeRecipientPhone'));
          return;
        }
        if (!state.formData.recipient_addr2) {
          showAlert(
            context.root.$t('Exchange.ExchangeRecipientDetailsAddress')
          );
          return;
        }

        if (state.payMehodStatus === '계좌' && !state.formData.bankName) {
          showAlert(context.root.$t('Exchange.ExchangeBankName'));
          return;
        }
        if (state.payMehodStatus === '계좌' && !state.formData.accountNumber) {
          showAlert(context.root.$t('Exchange.ExchangeBankAccount'));
          return;
        }
        if (!state.formData.ExChangeCheck) {
          showAlert(context.root.$t('Exchange.ExchangeAgreeCheck'));
          return;
        }

        if (
          state.reasonForReturn === '오배송' ||
          state.reasonForReturn === '상품불량' ||
          state.reasonForReturn === '상품상이'
        ) {
          if (state.formData.files.length < 1) {
            showAlert(context.root.$t('Exchange.AlertFiles'));
            return;
          }
        }

        showConfirm(context.root.$t('Exchange.AlertHow2'), () => {
          console.log(state);
          console.log(state.isAgreeCheck);
          if (state.isAgreeCheck !== true) {
            showAlert(context.root.$t('Exchange.AlertAgree2'));
            return false;
          }
          handleSubmitReturnRequest();
        });
      },
      handleCancel() {
        showConfirm(context.root.$t('Exchange.AlertReturnCancel'), () => {
          context.emit('close');
        });
      },
    };
  },
  destroyed() {
    unlockBodyClassnameHidden();
    removeBodyOverflowHidden();
  },
};
const phoneNumbers = ['010', '011', '017', '019', '070'].map((val) => ({
  key: val,
  label: val,
}));
</script>

<style scoped>
.return_form .detail_fee {
  margin-top: 20px;
}

.phone_input {
  align-items: start;
}

.phone_input > * {
  width: 33% !important;
}

.phone_input > input {
  border: 1px solid #a8a8a8;
  height: 40px;
  margin: 0 !important;
  margin-left: 10px !important;
  padding: 0 10px;
}
</style>
