<template>
  <div class="inner">
    <ul class="sub_tab disflex">
      <li :class="`width25 ${gigan === 0 ? 'on' : ''}`">
        <button type="button" @click="handleClickGigan(0)">
          {{ $t('Period.Week') }}
        </button>
      </li>
      <li :class="`width25 ${gigan === 1 ? 'on' : ''}`">
        <button type="button" @click="handleClickGigan(1)">
          {{ $t('Period.Month') }}
        </button>
      </li>
      <li :class="`width25 ${gigan === 2 ? 'on' : ''}`">
        <button type="button" @click="handleClickGigan(2)">
          {{ $t('Period.SixMonth') }}
        </button>
      </li>
      <li :class="`width25 border_none ${gigan === 3 ? 'on' : ''}`">
        <button type="button" @click="handleClickGigan(3)">
          {{ $t('Period.Year') }}
        </button>
      </li>
    </ul>

    <div class="calenda_input mt10">
      <form action="" class="wrap_flex">
        <label for=""><input v-model="startDate" type="date" /></label>
        <i>~</i>
        <label for=""><input v-model="endDate" type="date" /></label>
        <button
          type="button"
          class="white_search"
          @click="handleChangeGigan"
        ></button>
      </form>
    </div>

    <slot />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import moment from 'moment';
export default {
  props: ['value'],
  setup(props, context) {
    const state = reactive({
      gigan: 0,
      startDate: props.value[0],
      endDate: props.value[1],
    });

    if (!props.value[1]) {
      const date = moment();
      state.endDate = date.format('YYYY-MM-DD');
    }

    const handleClickGigan = gigan => {
      const date = moment(state.endDate);

      state.gigan = gigan;

      switch (gigan) {
        case 0:
          date.subtract('weeks', 1);
          break;
        case 1:
          date.subtract('months', 1);
          break;
        case 2:
          date.subtract('months', 6);
          break;
        case 3:
          date.subtract('years', 1);
          break;
        default:
          break;
      }

      state.startDate = date.format('YYYY-MM-DD');

      context.emit('input', [state.startDate, state.endDate]);
      context.emit('change', [state.startDate, state.endDate]);
    };

    handleClickGigan(state.gigan);

    return {
      handleClickGigan,

      ...toRefs(state),

      handleChangeGigan() {
        context.emit('input', [state.startDate, state.endDate]);
        context.emit('change', [state.startDate, state.endDate]);
      },
    };
  },
};
</script>

<style></style>
