<template>
  <span class="cqa_dtm"
    >{{ $t('CustomerService.ProductInquiry') }}: {{ cqa_dtm }}</span
  >
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import moment from 'moment';

export default {
  props: ['date'],
  setup(props) {
    const state = reactive({
      cqa_dtm: null,
    });
    state.cqa_dtm = moment(props.date).format('YYYY-MM-DD');
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.cqa_dtm {
  font-size: 12px;
  color: #a8a8a8;
}
</style>
