import { axios } from '@/plugins/axios';

export default {
  inquiry(params) {
    return axios.post('service/inquiry', params);
  },
  noticeInquiry(params) {
    return axios.post('service/inquiry2', params);
  },
  noticeInquiryList(params) {
    return axios.post('service/get_inquiry2_list', params);
  },
  updateInquiryList(params) {
    return axios.post('service/update_inquiry2', params);
  },
  deleteInquiry(params) {
    return axios.post('service/inquiry2_del', params);
  },
};
