<template>
  <div class="delivery_tracking_pop_wrap">
    <iframe
      :height="ativeHeight"
      :src="deliveryTrackingUrl"
      scrolling="no"
      style="border: none"
      width="100%"
    />
  </div>
</template>

<script>
import { ref } from 'vue-demi';

export default {
  name: 'DeliveryTrackingViewPop',
  props: ['order', 'provider'],
  setup(props) {
    const deliveryTrackingUrl = ref(
      props.provider.deliver_url
        ? props.provider.deliver_url
        : props.provider.delivery_url
    );
    const ativeHeight = ref(window.innerHeight - 144);
    return {
      deliveryTrackingUrl,
      ativeHeight,
    };
  },
};
</script>

<style scoped></style>
