<template>
  <down-full-up-popup :title="$t('Button.DepositInfo')" @close="$emit('close')">
    <!-- ^ 이 거에 대한 기준 데이터가 아직 정확하지 않음 ^-->
    <div slot="body" class="inner">
      <ul class="agree_content">
        <li class="order_agreement pb6 mt20" v-if="deposit">
          {{ $t('CancelList.EndDate', { Date: endDateValue }) }}
        </li>
        <li class="table_wrap">
          <div>
            <table class="table_box">
              <colgroup>
                <col style="width: 27%" />
                <col style="width: 73%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <!--                    {{ paymentInfoTitle.payMethodName }}-->
                    {{ $t('Order.DepositBank') }}
                  </th>
                  <td v-if="deposit">{{ deposit.vbank_name }}</td>
                </tr>
                <tr>
                  <th>
                    <!--                    {{ paymentInfoTitle.payName }}-->
                    {{ $t('Order.AccountNumber') }}
                  </th>
                  <td v-if="deposit">{{ deposit.vbank_num }}</td>
                </tr>
                <tr>
                  <th>
                    <!--                    {{ paymentInfoTitle.payDate }} -->
                    {{ $t('Order.AccountName') }}
                  </th>
                  <td v-if="deposit">{{ deposit.vbank_owner }}</td>
                </tr>
                <tr>
                  <th>
                    <!--                    {{ paymentInfoTitle.amountPayment }}-->
                    {{ $t('Order.DepositAmount') }}
                  </th>
                  <td v-if="deposit">
                    ₩ {{ deposit.total_price.numberFormat() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ul>
    </div>
    <template slot="footer">
      <div>
        <button
          type="button"
          class="black_btn font4 btn_pop_open pos_fixed0"
          style="width: 100%"
          @click="$emit('close')"
        >
          {{ $t('Popup.Confirm') }}
        </button>
      </div>
    </template>
  </down-full-up-popup>
</template>

<script>
import moment from 'moment';
import DownFullUpPopup from '../../../popups/DownFullUpPopup.vue';
import { reactive, toRefs } from 'vue-demi';

export default {
  props: ['deposit'],
  components: { DownFullUpPopup },
  setup({ deposit }, context) {
    const weeknames = [
      context.root.$t('Period.Monday'),
      context.root.$t('Period.Tuesday'),
      context.root.$t('Period.Wednesday'),
      context.root.$t('Period.Thursday'),
      context.root.$t('Period.Friday'),
      context.root.$t('Period.Saturday'),
      context.root.$t('Period.Sunday'),
    ];
    const date = moment(`${deposit.upd_dtm}`);
    const yyyymmdd = date.add(3, 'd').format('YYYY-MM-DD');
    const day = date.day();
    const state = reactive({});

    // switch (state.paymentMethod) {
    //   case 'CARD':
    //     state.paymentInfoTitle.payMethodName = context.root.$t(
    //       'CancelList.CardInfo1'
    //     );
    //     state.paymentInfoTitle.payName = context.root.$t(
    //       'CancelList.CardInfo2'
    //     );
    //     state.paymentInfoTitle.payDate = context.root.$t(
    //       'CancelList.CardInfo3'
    //     );
    //     state.paymentInfoTitle.amountPayment = context.root.$t(
    //       'CancelList.CardInfo4'
    //     );
    //     order.vbank_num = state.paymentMethodInfo;
    //     // order.vbank_name = order.card_name2;
    //     order.vbank_owner = order.upd_dtm.substr(0, 10);
    //     break;
    //   default:
    //     state.paymentInfoTitle.payMethodName = '';
    //     state.paymentInfoTitle.payName = '';
    //     state.paymentInfoTitle.payDate = '';
    //     order.vbank_num = '';
    //     order.vbank_name = '';
    //     order.vbank_owner = '';
    //     state.paymentMethod = null;
    // }

    return {
      ...toRefs(state),
      endDateValue: `${yyyymmdd}(${weeknames[day]})`,
    };
  },
};
</script>

<style></style>
