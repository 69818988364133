<template>
  <down-full-up-popup :title="$t('Button.SellerInfo')" @close="$emit('close')">
    <div v-if="brand" slot="body" class="inner p04">
      <ul class="agree_content">
        <li class="table_wrap">
          <div>
            <table class="table_box">
              <colgroup>
                <col style="width: 27%" />
                <col style="width: 73%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ $t('CustomerService.StoreName') }}</th>
                  <td>{{ brand.den_name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('Delivery.PhoneNum') }}</th>
                  <td>{{ brand.cs_no }}</td>
                </tr>
                <tr>
                  <th>{{ $t('Exchange.StoreAddr') }}</th>
                  <td>{{ brand.seller_addr1 }} {{ brand.seller_addr2 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li class="order_agreement">
          {{ $t('CancelList.ReturnDescription1') }}
        </li>
        <li class="order_agreement">
          {{ $t('CancelList.ReturnDescription2') }}
        </li>
      </ul>
    </div>
    <div slot="footer">
      <button
        type="button"
        class="black_btn font4 btn_pop_open pos_fixed0"
        style="width: 100%"
        @click="$emit('close')"
      >
        {{ $t('Popup.Confirm') }}
      </button>
    </div>
  </down-full-up-popup>
</template>

<script>
import { ref } from 'vue-demi';
import DownFullUpPopup from '../../../popups/DownFullUpPopup.vue';
import BrandAPI from '../../../../apis/BrandAPI';
import useUserInfo from '../../../../composables/user/useUserInfo';

export default {
  props: ['den_id'],
  components: { DownFullUpPopup },
  setup(props) {
    const { info } = useUserInfo();
    const brand = ref(null);

    BrandAPI.getBrand({
      mem_id: info?.mem_id,
      den_id: props.den_id,
    }).then((res) => {
      brand.value = res.data.result_data;
    });

    return {
      brand,
    };
  },
};
</script>

<style></style>
