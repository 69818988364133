<template>
  <div class="group_mem">
    <h6
      class="font4 fontWb mt10 filterDiv deposit_ing disflex"
      style="justify-content: space-between"
    >
      <strong>{{ OrderStatus[product.status] }}</strong>

      <input
        v-if="isSelect"
        :id="`order-product-${product.cod_id}`"
        :data-cod_id="product.cod_id"
        style="margin-right: 15px"
        type="checkbox"
        @change="$emit('change:checked', $event.target.checked, product)"
      />
    </h6>

    <router-link v-if="isLink" :to="link">
      <div class="order_con ver2 disflex">
        <template
          v-if="product.is_gubun !== '일반연동' && product.is_delete !== 'y'"
        >
          <image-bg-src :baseURL="product.cit_file_1" class="prod_img left" />
        </template>

        <template v-else>
          <image-bg-src :src="product.cit_file_1" class="prod_img left" />
        </template>

        <div class="right">
          <span class="ellipsis">
            {{ product.cit_name }}
          </span>
          <span class="mt14">{{ product.cde_title }}</span>
          <span v-if="product.cancel_qty === product.qty"
            >{{ product.cancel_qty }} 개</span
          >
          <span v-else-if="product.refund_qty"
            >{{ product.refund_qty }} 개
          </span>
          <span v-else-if="product.change_qty"
            >{{ product.change_qty }}개
          </span>
          <span v-else>{{ product.qty - product.cancel_qty }} 개</span>
          <!-- 금액 -주문배송조회-->
          <span
            v-if="
              product.status === deliveryQty ||
              product.status === 'PAYMENT' ||
              product.status === 'REQUEST'
            "
            class="flex_top fontNum"
          >
            ₩ {{ priceTotal.numberFormat() }}
          </span>
          <!-- 금액 - 취소 내역-->
          <span
            v-else-if="product.status === cancelQty"
            class="flex_top fontNum"
          >
            ₩ {{ product.total_cancel_price.numberFormat() }}
          </span>
          <!-- 금액 -교환 내역-->
          <span
            v-else-if="product.status === changeQty"
            class="flex_top fontNum"
          >
            ₩ {{ product.total_change_price.numberFormat() }}
          </span>
          <!-- 금액 -반품 내역-->
          <span
            v-else-if="product.status === returnQty"
            class="flex_top fontNum"
          >
            ₩ {{ product.total_rufund_price.numberFormat() }}
          </span>
        </div>
      </div>
    </router-link>

    <label v-else :for="`order-product-${product.cod_id}`">
      <div class="order_con ver2 disflex">
        <template
          v-if="product.is_gubun !== '일반연동' && product.is_delete !== 'y'"
        >
          <image-bg-src :baseURL="product.cit_file_1" class="prod_img left" />
        </template>
        <template v-else>
          <image-bg-src :src="product.cit_file_1" class="prod_img left" />
        </template>

        <div class="right">
          <span class="ellipsis">
            {{ product.cit_summary }}
          </span>
          <span class="mt14">{{ product.cde_title }}</span>
          <span>{{ product.qty }}개</span>
          <span class="flex_top fontNum">
            ₩ {{ Number(product.order.total_price).numberFormat() }}
          </span>
        </div>
      </div>
    </label>
    <div v-if="isShowDetail()" class="detail_fee">
      <ul v-if="product.is_detail" class="wrap_flex">
        <li>할인금액</li>
        <li v-if="product.order">
          <div v-if="product.total_cancel_price" class="font2">
            ₩
            {{
              (
                Number(product.use_coupon_value) +
                Number(product.use_grade_value) +
                Number(product.use_point_value)
              ).numberFormat()
            }}
          </div>
          <div v-else class="font2">
            ₩
            {{
              (
                Number(product.order.use_coupon) +
                Number(product.order.use_point)
              ).numberFormat()
            }}
          </div>
        </li>
        <li v-if="product.change_qty || product.refund_qty">
          ₩ {{ Number(product.total_sale_price).numberFormat() }}
        </li>

        <li>할인적용금액</li>
        <li v-if="product.order">
          <div class="font2">
            ₩
            {{
              product.total_cancel_price !== 0
                ? (
                    (Number(product.cit_price) -
                      Number(product.use_coupon_value) -
                      Number(product.use_grade_value) -
                      Number(product.use_point_value)) *
                    product.qty
                  ).numberFormat()
                : Number(product.total_cancel_price).numberFormat()
            }}
          </div>
        </li>

        <li v-if="product.change_qty">
          ₩
          {{
            product.qty === product.change_qty
              ? (
                  Number(product.total_change_price) +
                  Number(product.use_coupon_gap)
                ).numberFormat()
              : product.total_change_price
          }}
        </li>
        <li v-if="product.refund_qty">
          ₩
          {{
            product.qty === product.refund_qty
              ? (
                  Number(product.total_rufund_price) +
                  Number(product.use_coupon_gap)
                ).numberFormat()
              : product.total_rufund_price
          }}
        </li>
        <li>배송비</li>
        <li v-if="product.order">
          {{
            product.delivery_price === 0
              ? '무료배송'
              : '₩ ' + product.delivery_price_value
          }}
        </li>
        <li v-if="product.change_qty || product.refund_qty">
          ₩ {{ Number(product.delivery_type_fee).numberFormat() }}
        </li>
      </ul>

      <!-- 입금전 -->
      <template v-if="isShowButton">
        <template v-if="product.status === 'REQUEST'">
          <template v-if="path === '/mypage/transaction-proof'">
            <div class="mtb10">
              <button
                class="line_btnG btn_pop_open"
                @click="isShowVbankInfo = true"
              >
                {{ $t('Button.CreditCardSlip') }}
              </button>
            </div>
          </template>
          <template v-else>
            <div class="mtb10 disflex">
              <button
                class="line_btnG btn_pop_open"
                @click="isShowVbankInfo = true"
              >
                {{ $t('Order.DepositInfo') }}
              </button>
              <button
                class="line_btnG btn_pop_open"
                @click="isShowCancel = true"
              >
                {{ $t('OrderState.OrderCancel') }}
              </button>
            </div>
          </template>
        </template>

        <!-- 결제완료 -->
        <template v-if="product.status === 'PAYMENT'">
          <template v-if="path === '/mypage/transaction-proof'">
            <div class="mtb10">
              <button
                class="line_btnG btn_pop_open"
                @click="isShowVbankInfo = true"
              >
                {{ $t('Button.CreditCardSlip') }}
              </button>
            </div>
          </template>
          <template v-else>
            <div class="mtb10">
              <!-- <button class="line_btnG btn_pop_open">입금정보</button> -->
              <button
                class="line_btnG btn_pop_open"
                @click="isShowCancel = true"
              >
                {{
                  $t('Button.StateCancel', {
                    State2: $t('CustomerService.Order'),
                  })
                }}
              </button>
            </div>
          </template>
        </template>

        <!-- 배송중 -->
        <template
          v-if="
            product.status === 'DELIVERY' ||
            product.status === 'CHANGE_ING' ||
            product.status === 'CHANGE_DELIVERY_ING' ||
            product.status === 'RETURN_ING'
          "
        >
          <div class="mtb10">
            <button class="line_btnG" @click="handleDeliverlyTracking(product)">
              {{ $t('Button.ShippingLookup') }}
            </button>
          </div>
        </template>

        <!-- 배송완료 -->
        <template v-if="product.status === 'DELIVERY_COMPLETE'">
          <div class="mtb10 disflex">
            <button class="line_btnG" @click="handleDeliverlyTracking(product)">
              {{ $t('Button.ShippingLookup') }}
            </button>
            <button class="line_btnG" @click="isShowProductReview = true">
              {{ $t('Mypage.Review') }}
            </button>
            <button
              class="line_btnG btn_pop_open"
              @click="isShowRequestReturn = true"
            >
              {{
                $t('Button.StateRegister', {
                  State1: $t('CustomerService.Return'),
                })
              }}
            </button>
            <button
              class="line_btnG btn_pop_open"
              @click="isShowRequestChange = true"
            >
              {{
                $t('Button.StateRegister', {
                  State1: $t('CustomerService.Exchange'),
                })
              }}
            </button>
          </div>
        </template>

        <!-- 반품신청 -->
        <template v-if="product.status === 'RETURN_REQUEST'">
          <div class="mtb10">
            <button class="line_btnG btn_pop_open" @click="isCancelView">
              {{
                $t('Button.StateCancel', {
                  State2: $t('CustomerService.Return'),
                })
              }}
            </button>
          </div>
        </template>

        <!-- 교환신청 -->
        <template v-if="product.status === 'CHANGE_REQUEST'">
          <div class="mtb10">
            <button class="line_btnG btn_pop_open" @click="isCancelView">
              {{
                $t('Button.StateCancel', {
                  State2: $t('CustomerService.Exchange'),
                })
              }}
            </button>
          </div>
        </template>
      </template>
    </div>

    <order-vbank-info
      v-if="isShowVbankInfo"
      :deposit="product.order"
      @close="isShowVbankInfo = false"
    />

    <order-cancel
      v-if="isShowCancel"
      ref="order_cancel"
      :item="product"
      :order="order"
      @cancel="handleCancelOrder"
      @close="isShowCancel = false"
    />

    <request-return
      v-if="isShowRequestReturn"
      :item="product"
      :order="order"
      @IsRefundFeeOK="handleUpdate"
      @close="isShowRequestReturn = false"
    />

    <request-change
      v-if="isShowRequestChange"
      :item="product"
      :order="order"
      @close="isShowRequestChange = false"
    />
    <down-full-up-popup
      v-if="isShowProductReview"
      :title="$t('Review.WriteReview')"
      @close="handleCloseEvent"
    >
      <template slot="body">
        <review-view :product_id="product.cit_id" @close="handleCloseEvent" />
      </template>
    </down-full-up-popup>
    <down-full-up-popup
      v-if="isDeliveryTrackingPopVie.isShowPop"
      class="delivery_tracking"
      title="배송조회"
      @close="isDeliveryTrackingPopVie.isShowPop = false"
    >
      <DeliveryTrackingViewPop
        slot="body"
        :order="order"
        :provider="isDeliveryTrackingPopVie.isData"
      />
    </down-full-up-popup>
  </div>
</template>

<script>
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
import OrderStatus, {
  cancelStatus,
  changeStatus,
  returnStatus,
  deliveryStatus,
} from '../../../consts/OrderStatus';
import OrderVbankInfo from './popups/OrderVbankInfo.vue';
import { computed, onMounted, reactive, toRefs } from 'vue-demi';
import OrderCancel from './popups/OrderCancel.vue';
import RequestReturn from './popups/RequestReturn.vue';
import RequestChange from './popups/RequestChange.vue';
import ReviewView from '@Views/products/ReviewView';
import DownFullUpPopup from '@Popups/DownFullUpPopup';
import OrderAPI from '@/apis/OrderAPI';
import useUserInfo from '@Composables/user/useUserInfo';
import useConfirm from '@Composables/dialogs/useConfirm';
import useAlert from '@Composables/dialogs/useAlert';
import DeliveryTrackingViewPop from '@Popups/DeliveryTrackingViewPop.vue';

const isButtonSatus = [
  'REQUEST',
  'PAYMENT',
  'DELIVERY',
  'DELIVERY_COMPLETE',
  'RETURN_REQUEST',
  'CHANGE_REQUEST',
  'COMPLETE',
  'CHANGE_ING',
  'CHANGE_DELIVERY_ING',
  'RETURN_ING',
];

export default {
  props: [
    'item',
    'order',
    'type',
    'isShowButton',
    'isSelect',
    'isLink',
    'isShowOrderID',
    'canclePrice',
  ],
  components: {
    DeliveryTrackingViewPop,
    DownFullUpPopup,
    ReviewView,
    ImageBgSrc,
    OrderVbankInfo,
    OrderCancel,
    RequestReturn,
    RequestChange,
  },

  setup(props, context) {
    const { info } = useUserInfo();
    const { showConfirm } = useConfirm();
    const { showAlert } = useAlert();
    const state = reactive({
      isShowCancel: false,
      isShowVbankInfo: false,
      isShowRequestReturn: false,
      isShowRequestChange: false,
      isShowProductReview: false,
      product: props.item,
      path: context.root.$router.currentRoute.path,
      isCancelSet: false,
      isDeliveryTrackingPopVie: {
        isShowPop: false,
        isData: {},
      },
      isQty: false,
    });
    console.log('$$$$$$$$$$$$$$$$$$$$$$$####################');
    console.log(props.item);
    console.log(props.isLink);
    console.log('$$$$$$$$$$$$$$$$$$$$$$$####################');

    //총할인금액
    const totalDiscount = computed(() => {
      return (
        Number(props.item.use_coupon_value * props.item.qty) +
        Number(props.item.use_grade_value * props.item.qty) +
        Number(props.item.use_point_value * props.item.qty) +
        Number(props.item.use_coupon_gap)
      );
    });
    console.log(totalDiscount);
    //리스트 금액

    const priceTotal = computed(() => {
      const values =
        Number(state.product.cit_sale_price) * state.product.qty -
        Number(totalDiscount.value) +
        Number(
          state.product.delivery_price_value
            ? state.product.delivery_price_value
            : state.product.delivery_price
        );

      return values;
    });

    const cancelQty = cancelStatus.find(
      (item) => item === state.product.status
    );
    const returnQty = returnStatus.find(
      (item) => item === state.product.status
    );
    const changeQty = changeStatus.find(
      (item) => item === state.product.status
    );
    const deliveryQty = deliveryStatus.find(
      (item) => item === state.product.status
    );
    console.log('!!!!!!');
    console.log(returnQty === state.product.status);
    console.log('!!!!!!');
    let link = '';
    console.log(props.item, props.item);
    console.log(props.type);
    switch (props.type) {
      case 'detail':
        link = `/mypage/order/${props.item.order_id}`;
        break;
      case 'cancel':
        link = `/mypage/order-cancel/${props.item.order_id}/${props.item.cod_id}`;
        break;
      case 'change/return':
        link = `/mypage/order-change-return/${props.item.order_id}/${
          props.item.ccg_id ? props.item.ccg_id : props.item.crf_id
        }`;

        break;
      default:
        break;
    }
    let pattern2 = /[a-zA-Z]/; //영어

    onMounted(() => {
      const noEventBubbling = (e) => {
        e.preventDefault();
      };

      document.querySelectorAll('.detail_fee button').forEach((node) => {
        node.addEventListener('click', noEventBubbling);
      });
    });
    const isCancelView = () => {
      if (state.product.status === 'RETURN_REQUEST') {
        console.log('반품');
        showConfirm(context.root.$t('Exchange.AlertReturnCancel'), () => {
          OrderAPI.getReturnCancel({
            mem_id: info?.mem_id,
            order_id: state.product.order_id,
            cod_id: state.product.cod_id,
            crf_id: state.product.crf_id,
          })
            .then(() => {
              showAlert(context.root.$t('Exchange.AlertReturnComplete'));
              context.root.$router.push('/mypage');
            })
            .catch((error) => {
              console.log(error);
              showAlert(context.root.$t('Exchange.AlertError'));
              context.root.$router.push('/mypage');
            });
        });
      } else if (state.product.status === 'CHANGE_REQUEST') {
        console.log('취소');
        showConfirm(context.root.$t('Exchange.AlertChangeCancel'), () => {
          OrderAPI.getChangesCancel({
            mem_id: info?.mem_id,
            order_id: state.product.order_id,
            cod_id: state.product.cod_id,
            ccg_id: state.product.ccg_id,
          })
            .then(() => {
              showAlert(context.root.$t('Exchange.AlertChangeComplete'));
              context.root.$router.push('/mypage');
            })
            .catch((error) => {
              console.log(error);
              showAlert(context.root.$t('Exchange.AlertError'));
              context.root.$router.push('/mypage');
            });
        });
      }
    };
    return {
      link,
      pattern2,
      ...toRefs(state),
      OrderStatus,
      cancelQty,
      returnQty,
      changeQty,
      deliveryQty,
      totalDiscount,
      priceTotal,
      isCancelView,
      handleCancelOrder() {
        const product = { ...state.product };
        product.status = 'CANCEL';
        state.product = product;

        state.isShowCancel = false;
      },
      handleUpdate() {
        context.emit('UpdateOrderItem', true);
      },
      isShowDetail() {
        if (state.product.is_detail) {
          return true;
        }
        if (
          props.isShowButton &&
          isButtonSatus.indexOf(state.product.status) > -1
        ) {
          return true;
        }
      },
      handleCloseEvent() {
        state.isShowProductReview = false;
      },
      handleDeliverlyTracking(target) {
        console.log('구스플로우 붙여야댐');
        console.log(target);
        state.isDeliveryTrackingPopVie.isShowPop = true;
        state.isDeliveryTrackingPopVie.isData = target;
      },
    };
  },
};
</script>

<style scoped>
.detail_fee .wrap_flex {
  width: auto;
}

.arr_right {
  position: absolute;
  top: 0;
  width: calc(100% - 18px);
}
</style>
