<template>
  <div class="group_order_list">
    <div class="inner p04">
      <order-item-brand
        v-for="provider in item.provider"
        :key="provider.cod_id"
        :isLink="isLink"
        :isSelect="isSelect"
        :isShowButton="isShowButton"
        :item="provider"
        :order="item"
        :type="type"
        @orderItemUpdate="handleOrderItem"
        @change:checked="handleChangeChecked"
      />
    </div>
  </div>
</template>

<script>
import OrderItemBrand from "./OrderItemBrand.vue";
import { reactive, toRefs } from "vue-demi";

export default {
  name: "OrderItem",
  components: { OrderItemBrand },
  props: {
    isShowOrderID: { default: true },
    item: {},
    type: {},
    isLink: { default: true },
    isSelect: { default: false },
    isShowButton: { default: true },
    isShowOrder: { default: false },
  },
  setup(props, context) {
    const state = reactive({
      // isDateValue: false,
    });
    console.log("#######################################");
    console.log(props.type);
    console.log(props.item.provider);
    console.log("#######################################");

    return {
      ...toRefs(state),
      handleChangeChecked(checked, product) {
        context.emit("change:checked", checked, product);
      },
      handleOrderItem() {
        context.emit("orderItemUpdate", true);
      },
    };
  },
};
</script>

<style scoped>
.status .order_list_layout .group_order_list {
  padding-top: 42px !important;
  position: relative;
}
</style>
