<template>
  <down-full-up-popup
    :title="$t('Review.Exchange')"
    class="change-order"
    @close="$emit('close')"
  >
    <template slot="body">
      <div class="group_order_list detail_condition">
        <div class="inner ptb14 border_bottom flex_col">
          <span class="font2 colora8">{{ order.ins_dtm.substr(0, 10) }}</span
          ><strong
            >{{ $t('OrderInquiry.OrderNumber') }} {{ order.order_id }}</strong
          >
        </div>
        <div class="inner p04">
          <div class="group_list">
            <div class="pro_desc_box type4 disflex">
              <router-link :to="`/brand/detail/${item.den_id}`">
                <image-bg-src
                  :src="
                    orderItem.img_url ? orderItem.img_url : item.seller_picture
                  "
                  class="profile_img"
                />
                <div class="profile_user new_title">
                  <h3 class="name fontEn">{{ item.den_name }}</h3>
                  <p class="desc">{{ item.provider_summary }}</p>
                </div>
              </router-link>
              <template v-if="device_type === 'pc'">
                <button
                  class="radi_btnG flex_right"
                  type="button"
                  @click="isShowBrandDetail = true"
                >
                  {{ $t('Button.SellerInfo') }}
                </button>
              </template>

              <template v-else>
                <a :href="`tel:${item.cs_no}`" class="radi_btnG flex_right">
                  {{ $t('Button.MakeAPhone') }}
                </a>
              </template>
            </div>
            <div class="group_mem">
              <h6 class="font4 fontWb mt10 filterDiv deposit_ing">
                {{ OrderStatus[item.status] }}
              </h6>
              <div class="order_con ver2 disflex">
                <template v-if="item.is_gubun !== '일반연동'">
                  <image-bg-src
                    :baseURL="item.cit_file_1"
                    class="prod_img left"
                  />
                </template>

                <template v-else>
                  <image-bg-src :src="item.cit_file_1" class="prod_img left" />
                </template>

                <div class="right">
                  <span class="ellipsis">
                    {{ item.cit_summary }}
                  </span>
                  <span class="mt14">{{ item.cde_title }}</span>
                  <span>{{ item.qty }}개</span>
                  <span class="flex_top fontNum">
                    ₩ {{ Number(item.cit_sale_price).numberFormat() }}
                  </span>
                </div>
              </div>
              <div class="detail_fee border_top9">
                <ul class="wrap_flex">
                  <li>{{ $t('OrderInquiry.PaymentInfo') }}</li>
                  <li>{{ item.product_code }}</li>
                </ul>
              </div>
              <div class="table_wrap">
                <div>
                  <table class="table_box">
                    <colgroup>
                      <col style="width: 27%" />
                      <col style="width: 73%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>{{ $t('Button.OrderPrice') }}</th>
                        <td>₩ {{ Number(item.cit_price).numberFormat() }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Basket.DeliveryFee') }}</th>
                        <td>₩ {{ orderItem.order.delivery_price }}</td>
                      </tr>

                      <tr>
                        <th>{{ $t('OrderInquiry.PaymentType') }}</th>
                        <td>{{ getPayName(pay) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Button.ExchangeQuantity') }}</th>
                        <td class="evidence disflex">
                          <number-counter
                            v-model="temp_qty"
                            :max="orderQty.max"
                            :min="orderQty.min + 1"
                          />
                          <!--                          <div class="flex_right">-->
                          <!--                            <button-->
                          <!--                              class="black_btn width30"-->
                          <!--                              @click="qty = temp_qty"-->
                          <!--                            >-->
                          <!--                              {{ $t('Button.Quantity') }}-->
                          <!--                            </button>-->
                          <!--                          </div>-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border_top reReason_layout">
          <h6 class="allPage_tit">{{ $t('Button.ExchangeReason') }}</h6>
          <div class="inner p04">
            <ul class="wrap_flex basic_info">
              <li class="disflex input_chk_box">
                <label class="color37 font2">
                  <input
                    class="check_inputs"
                    type="checkbox"
                    value="상품 불량"
                    @click="handleCheck"
                  />
                  {{ $t('Button.Defected') }}
                </label>
              </li>
              <li class="disflex input_chk_box">
                <label class="color37 font2">
                  <input
                    class="check_inputs"
                    type="checkbox"
                    value="오배송"
                    @click="handleCheck"
                  />
                  {{ $t('Button.Wrong') }}
                </label>
              </li>
              <li class="disflex input_chk_box">
                <label class="color37 font2">
                  <input
                    class="check_inputs"
                    type="checkbox"
                    value="상품 정보 상이"
                    @click="handleCheck"
                  />
                  {{ $t('Button.InfoDifference') }}
                </label>
              </li>
              <li class="disflex input_chk_box">
                <label class="color37 font2">
                  <input
                    class="check_inputs"
                    type="checkbox"
                    value="기타(사유입력)"
                    @click="handleCheck"
                  />
                  {{ $t('Button.EctEnter') }}
                </label>
              </li>
              <li v-if="isEtc" class="ect_reason">
                <label class="basic_tit" for="cancel9"></label>
                <input
                  id="cancel9"
                  v-model="reasonForExchange"
                  :placeholder="$t('Exchange.ReturnReason')"
                  class="deep_type"
                  type="text"
                />
              </li>
              <li class="write_warp mt14">
                <h6 class="write_tit">{{ $t('Review.AlertAttach') }}</h6>
                <ul class="pt_add_box p0">
                  <li
                    v-for="(url, idx) in urls"
                    :key="idx"
                    :class="url ? 'added_img' : ''"
                    :style="url ? `background-image: url(${url})` : ''"
                    @click="handleClickImage(url)"
                  >
                    <label v-if="url === ''" for="files">
                      <input
                        id="files"
                        accept="image/*"
                        multiple
                        type="file"
                        @change="handleChangeFile"
                      />
                    </label>
                  </li>
                </ul>
                <p class="">
                  {{ $t('Exchange.ExchangeDescription1') }}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div class="border_top mt30">
          <h6 class="allPage_tit">{{ $t('Exchange.ExchangeDescription5') }}</h6>
          <div class="inner">
            <div class="shop_user">
              <ul class="wrap_flex basic_info type02">
                <li>
                  <label class="basic_tit" for="byer_n">
                    {{ $t('Exchange.ExchangeDescription6') }}
                  </label>
                  <input
                    v-model="recipient_name"
                    :placeholder="$t('Join.InputName')"
                    class="deep_type"
                    type="text"
                  />
                </li>
                <li>
                  <label class="font4" for="phoneNum">{{
                    $t('Delivery.PhoneNumber')
                  }}</label>
                  <div class="phone_input disflex">
                    <select-box
                      v-model="phone[0]"
                      :items="phoneNumbers"
                      class="type01"
                      style="height: 40px"
                    />
                    <input-number
                      v-model="phone[1]"
                      :maxLength="4"
                      class="btn_group"
                    />
                    <input-number
                      v-model="phone[2]"
                      :maxLength="4"
                      class="btn_group"
                    />
                  </div>
                </li>
                <li>
                  <div class="userinfo wrap_flex p0">
                    <label class="basic_tit" for="">{{
                      $t('Delivery.ZipCode')
                    }}</label>
                    <input
                      v-model="recipient_zip"
                      class="btn_group deep_type back_color7"
                      readonly
                      type="text"
                    />
                    <button class="black_btn" @click="handleClickJuso">
                      {{ $t('Delivery.ZipCode') }}
                    </button>
                  </div>
                </li>
                <li>
                  <label class="basic_tit" for="byer_tel">{{
                    $t('Delivery.EditAddress')
                  }}</label>
                  <input
                    v-model="recipient_addr1"
                    class="deep_type back_color7"
                    readonly
                    type="text"
                  />
                </li>
                <li>
                  <label class="basic_tit" for="buis_num">{{
                    $t('Delivery.DetailAddress')
                  }}</label>
                  <input
                    id="buis_num"
                    v-model="recipient_addr2"
                    class="deep_type"
                    type="text"
                  />
                </li>
              </ul>
            </div>
          </div>
          <ul class="order_tip cancel_tip">
            <!--            <li class="agree_block align_top input_chk_box">-->
            <!--              <input id="retrunNum" v-model="isRequestChange" type="checkbox" />-->
            <!--              <label-->
            <!--                class="chkbox"-->
            <!--                for="retrunNum"-->
            <!--                v-html="$t('Exchange.ExchangeDescription8')"-->
            <!--              ></label>-->
            <!--            </li>-->
            <li class="pt10">
              {{ $t('Exchange.ExchangeDescription9') }}
            </li>
            <li>{{ $t('Exchange.ExchangeDescription10') }}</li>
            <li>{{ $t('Exchange.ExchangeDescription11') }}</li>
            <li>{{ $t('Exchange.ExchangeDescription12') }}</li>
            <li>{{ $t('Exchange.ExchangeDescription13') }}</li>
            <li>{{ $t('Exchange.ExchangeDescription14') }}</li>
            <li>{{ $t('Exchange.ExchangeDescription15') }}</li>
          </ul>
        </div>
      </div>
    </template>
    <template slot="footer">
      <button type="button" @click="handleCancel">
        {{ $t('Exchange.CancelButton') }}
      </button>
      <button class="" type="button" @click="handelSubmitChange">
        {{ $t('Review.Exchange') }}
      </button>
    </template>
  </down-full-up-popup>
</template>

<script>
import { checkPlatform } from '../../../../utils/Buy';
import DownFullUpPopup from '../../../popups/DownFullUpPopup.vue';
import OrderStatus from '../../../../consts/OrderStatus';
import SelectBox from '../../../Inputs/SelectBox/SelectBox.vue';
import InputNumber from '../../../Inputs/InputNumber/InputNumber.vue';
import { reactive, toRefs, watch } from 'vue-demi';
import {
  lockBodyClassnameHidden,
  removeBodyOverflowHidden,
  unlockBodyClassnameHidden,
} from '../../../../utils/CommonUtils';
import ImageBgSrc from '../../ImageBlock/ImageBgSrc.vue';
import NumberCounter from '../../../Inputs/NumberCounter/NumberCounter.vue';
import useToast from '../../../../composables/dialogs/useToast';
import useConfirm from '@Composables/dialogs/useConfirm';
import OrderAPI from '@/apis/OrderAPI';
import useAlert from '@Composables/dialogs/useAlert';

export default {
  props: ['item', 'order'],
  components: {
    DownFullUpPopup,
    SelectBox,
    InputNumber,
    ImageBgSrc,
    NumberCounter,
  },
  setup(props, context) {
    const order = props.item.order;

    const { showConfirm } = useConfirm();
    const { showAlert } = useAlert();

    const { addMsg } = useToast();

    lockBodyClassnameHidden();

    const state = reactive({
      files: [],
      urls: new Array(5).fill(''),
      qty: 1,
      temp_qty: 1,
      isShowBrandDetail: false,
      phone: ['', '', ''],
      recipient_name: order.recipient_name,
      recipient_phone: order.recipient_phone,
      recipient_zip: order.recipient_zip,
      recipient_addr1: order.recipient_addr1,
      recipient_addr2: order.recipient_addr2,
      isEtc: false,
      reasonForExchange: '',
      orderItem: '',
      pay: order.payMethod,
      payMethod: '',
      formData: {},
      isRequestChange: false,
      orderQty: {
        min: 0,
        max: 0,
      },
    });
    watch(
      () => state.temp_qty,
      (newValue) => {
        state.temp_qty = newValue;
      }
    );
    const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    const handleCheckOrderQty = async () => {
      const { data } = await OrderAPI.checkOrderDetailQty({
        cod_id: props.item.cod_id,
      });
      console.log('@@@@@@@@@@@@@@@@@');
      console.log(data);
      console.log(data.data.refund_qty);
      console.log(data.data.possible_qty);
      console.log('@@@@@@@@@@@@@@@@@');
      if (data.data.possible_qty === 0) {
        showAlert(
          context.root.$t('Exchange.ThisProductHasAlreadyBeenExchange')
        );
        context.emit('close');
      } else {
        state.orderQty.min = data.data.change_qty;
        state.orderQty.max = data.data.possible_qty;
      }
    };
    handleCheckOrderQty();
    const fileValidate = (files) => {
      let isImage = true;

      [...files].forEach((file) => {
        if (isImage) {
          isImage = imageTypes.indexOf(file['type'].toLowerCase()) > -1;
        }
      });

      if (isImage === false) {
        addMsg(context.root.$t('Review.AlertFormat'));
        return false;
      }

      if (state.files.length + files.length > 5) {
        addMsg(context.root.$t('Review.AlertMax'));
        return false;
      }

      return true;
    };

    state.orderItem = props.item;
    console.log(order);
    console.log(state.orderItem, '<-ㅇㅇㅇㅇㅇㅇㅇㅇ');
    if (props.item.order.status === 'PAYMENT') {
      state.payMethod = '신용카드';
    } else {
      state.payMethod = '계좌';
    }

    state.phone[0] = state.orderItem.order.recipient_phone.substring(0, 3);
    state.phone[1] = state.orderItem.order.recipient_phone.substring(3, 7);
    state.phone[2] = state.orderItem.order.recipient_phone.substring(7, 13);

    const handleSubMitRequestChange = async () => {
      await OrderAPI.getChangeRequest({
        mem_id: state.formData.mem_id,
        order_id: state.formData.order_id,
        cod_id: state.formData.cod_id,
        change_ea: state.formData.change_ea,
        exchange_reason: state.formData.exchange_reason,
        exchange_memo: state.formData.exchange_memo,
        recipient_memo: state.formData.recipient_memo,
        recipient_name: state.formData.recipient_name,
        recipient_phone: state.formData.recipient_phone,
        recipient_zip: state.formData.recipient_zip,
        recipient_addr1: state.formData.recipient_addr1,
        recipient_addr2: state.formData.recipient_addr2,
        files: state.formData.files,
        img_file1: state.formData.img_file1,
        img_file2: state.formData.img_file2,
        img_file3: state.formData.img_file3,
        img_file4: state.formData.img_file4,
        img_file5: state.formData.img_file5,
      })
        .then(() => {
          showAlert(`${context.root.$t('Exchange.AlertExchange')}`);
          context.root.$router.push('/mypage');
        })
        .catch((error) => {
          console.log(error);
          showAlert(`${context.root.$t('Exchange.AlertError')}`);
          context.root.$router.push('/mypage');
        });
    };

    return {
      ...toRefs(state),
      phoneNumbers,
      OrderStatus,
      device_type: checkPlatform(window.navigator.userAgent),
      //결제 타입
      getPayName(method) {
        switch (method) {
          case 'CARD':
            return context.root.$t('OrderInquiry.Card');
          case 'NAVER':
            return context.root.$t('OrderInquiry.NaverPay');
          case 'KAKAO':
            return context.root.$t('OrderInquiry.KakaoPay');
          case 'VBANK':
            return context.root.$t('OrderInquiry.VirtualAccount');
          case 'BANK':
            return context.root.$t('OrderInquiry.BankTransfer');
          default:
            return '';
        }
      },
      handleClickJuso() {
        new window.daum.Postcode({
          oncomplete: function (data) {
            state.recipient_zip = data.zonecode;
            state.recipient_addr1 = data.roadAddress;
          },
        }).open();
      },
      handleChangeFile(e) {
        const files = [...e.target.files];

        if (fileValidate(files)) {
          const urls = state.urls.filter((url) => url !== '');

          files.forEach((file) => {
            urls.push(URL.createObjectURL(file));
          });

          for (let i = urls.length; i < 5; i++) {
            urls.push('');
          }

          state.urls = urls;
          state.files = [...state.files, ...e.target.files];
        } else {
          e.target.value = '';
        }
      },
      handleClickImage(url) {
        if (url === '') return;

        const idx = state.urls.findIndex((value) => value === url);

        if (idx > -1) {
          const urls = [...state.urls];
          urls.splice(idx, 1);
          urls.push('');

          const files = [...state.files];
          files.splice(idx, 1);

          state.urls = urls;
          state.files = files;
        }
      },
      handleCheck(el) {
        let changeCheckValue = document.querySelectorAll('.check_inputs');
        changeCheckValue.forEach((el) => {
          el.checked = false;
        });
        el.target.checked = true;
        state.isEtc = false;
        console.log(el.target.value);
        if (el.target.value === '기타(사유입력)') {
          state.isEtc = true;
          state.reasonForExchange = '';
        } else {
          state.reasonForExchange = el.target.value;
        }
      },

      handelSubmitChange() {
        state.files.forEach((file, idx) => {
          state.formData[`img_file${idx + 1}`] = file;
        });

        state.formData.urls = state.urls;
        state.formData.mem_id = localStorage.getItem('mem_id');
        state.formData.order_id = state.orderItem.order.order_id;
        state.formData.cod_id = state.orderItem.cod_id;
        state.formData.change_ea = state.temp_qty;
        state.formData.exchange_reason = state.isEtc
          ? '기타(사유입력)'
          : state.reasonForExchange;
        state.formData.exchange_memo = state.isEtc
          ? state.reasonForExchange
          : '';
        state.formData.recipient_name = state.recipient_name;
        state.formData.recipient_phone = `${state.phone[0]}-${state.phone[1]}-${state.phone[2]}`;
        state.formData.recipient_zip = state.recipient_zip;
        state.formData.recipient_addr1 = state.recipient_addr1;
        state.formData.recipient_addr2 = state.recipient_addr2;
        state.formData.files = state.files;
        console.log('@@@@@@@@');
        console.log(state.formData);
        console.log('@@@@@@@@');
        // if (state.isEtc) {
        //   if (!state.formData.exchange_memo) {
        //     showAlert(context.root.$t('Exchange.ExchangeReason'));
        //     return;
        //   }
        // } else {
        if (!state.formData.exchange_reason) {
          showAlert(context.root.$t('Exchange.ExchangeReason'));
          return;
          // }
        }

        if (!state.formData.recipient_name) {
          showAlert(context.root.$t('Exchange.ExchangeRecipinetName'));
          return;
        }
        if (!state.formData.recipient_phone) {
          showAlert(context.root.$t('Exchange.ExchangeRecipientPhone'));
          return;
        }
        if (!state.formData.recipient_zip) {
          showAlert(context.root.$t('Exchange.ExchangeRecipientZip'));
          return;
        }
        if (!state.formData.recipient_addr2) {
          showAlert(
            context.root.$t('Exchange.ExchangeRecipientDetailsAddress')
          );
          return;
        }
        if (
          state.reasonForExchange === '오배송' ||
          state.reasonForExchange === '상품불량' ||
          state.reasonForExchange === '상품상이'
        ) {
          if (state.formData.files.length < 1) {
            showAlert(context.root.$t('Exchange.AlertFiles'));
            return;
          }
        }
        console.log(state.formData);
        showConfirm(context.root.$t('Exchange.AlertHow'), () => {
          handleSubMitRequestChange();
          // if (state.isRequestChange) {
          //
          // } //else {
          //   showAlert(`${context.root.$t('Exchange.AlertAgree')}`);
          //   return false;
          // }
        });
      },
      handleCancel() {
        showConfirm(context.root.$t('Exchange.AlertChangeCancel'), () => {
          context.emit('close');
        });
      },
    };
  },
  destroyed() {
    unlockBodyClassnameHidden();
    removeBodyOverflowHidden();
  },
};
const phoneNumbers = ['010', '011', '017', '019', '070'].map((val) => ({
  key: val,
  label: val,
}));
</script>

<style scoped>
.order_list_layout .group_order_list {
  padding-top: 0px;
}
.flex_col strong {
  padding-bottom: 10px;
}
.phone_input {
  align-items: start;
}
.phone_input > * {
  width: 33% !important;
}
.phone_input > input {
  border: 1px solid #a8a8a8;
  height: 40px;
  margin: 0 !important;
  margin-left: 10px !important;
  padding: 0 10px;
}

.change-order .inner {
  padding: 10px 0;
}
</style>
