<template>
  <div>
    <!-- 주문취소 폼 팝업-->
    <down-full-up-popup
      :title="$t('CustomerService.OrderCancel')"
      class="order-cancel"
      @close="$emit('close')"
    >
      <div slot="body" style="padding: 0">
        <div class="group_order_list detail_condition">
          <div class="inner ptb14 border_bottom flex_col">
            <span class="font2 colora8">
              {{ order.ins_dtm.substr(0, 10) }}</span
            >
            <strong
              >{{ $t('OrderInquiry.OrderNumber') }} {{ order.order_id }}</strong
            >
          </div>
          <div class="inner p04">
            <div class="group_list">
              <div class="pro_desc_box type4 disflex" style="border-top: none">
                <router-link :to="`/brand/detail/${provider.den_id}`">
                  <image-bg-src
                    :src="provider.seller_picture"
                    class="profile_img"
                  />
                  <div class="profile_user new_title">
                    <h3 class="name fontEn">{{ provider.den_name }}</h3>
                    <p class="desc">{{ provider.provider_summary }}</p>
                  </div>
                </router-link>
                <!--pc에서 판매자정보버튼 출력ㄱ-->
                <template v-if="device_type === 'pc'">
                  <router-link
                    :to="`/brand/detail/${item.den_id}`"
                    class="radi_btnG flex_right"
                  >
                    {{ $t('Button.SellerInfo') }}
                  </router-link>
                </template>
                <template v-else>
                  <a :href="`tel:${item.cs_no}`" class="radi_btnG flex_right">
                    {{ $t('Button.MakeAPhone') }}
                  </a>
                </template>
              </div>
              <div class="group_mem">
                <h6 class="font4 fontWb mt10 filterDiv deposit_ing">
                  {{ OrderStatus[item.status] }}
                </h6>
                <div class="order_con ver2 disflex">
                  <template v-if="item.is_gubun !== '일반연동'">
                    <image-bg-src
                      :baseURL="item.cit_file_1"
                      class="prod_img left"
                    />
                  </template>

                  <template v-else>
                    <image-bg-src
                      :src="item.cit_file_1"
                      class="prod_img left"
                    />
                  </template>

                  <div class="right">
                    <span class="ellipsis">
                      {{ item.cit_name }}
                    </span>
                    <span class="mt14">{{ item.cde_title }}</span>
                    <span>{{
                      $t('Button.Number', { Num: qty - item.cancel_qty })
                    }}</span>
                    <span class="flex_top fontNum">
                      ₩
                      {{
                        (
                          Number(priceTotal) +
                          Number(props.item.delivery_price_value)
                        ).numberFormat()
                      }}
                    </span>
                  </div>
                </div>
                <div class="detail_fee border_top9">
                  <ul class="wrap_flex">
                    <li>{{ $t('OrderInquiry.PaymentInfo') }}</li>
                    <li>{{ item.product_code }}</li>
                  </ul>
                </div>
                <div class="table_wrap ver2">
                  <div>
                    <table class="table_box">
                      <colgroup>
                        <col style="width: 27%" />
                        <col style="width: 73%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>{{ $t('Button.OrderPrice') }}</th>
                          <!--                          <td>-->
                          <!--                            ₩-->
                          <!--                            {{-->
                          <!--                              (item.cit_sale_price * item.qty).numberFormat()-->
                          <!--                            }}-->
                          <!--                          </td>-->
                          <td>
                            ₩
                            {{
                              (
                                (Number(item.cit_sale_price) -
                                  Number(item.use_coupon_value) -
                                  Number(item.use_grade_value) -
                                  Number(item.use_point_value)) *
                                  item.qty -
                                Number(item.use_coupon_gap)
                              ).numberFormat()
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>{{ $t('Basket.DeliveryFee') }}</th>
                          <td>
                            ₩
                            {{ props.item.delivery_price_value.numberFormat() }}
                          </td>
                        </tr>

                        <tr>
                          <th>{{ $t('OrderInquiry.PaymentType') }}</th>
                          <td>{{ getPayMethodName(item.order.payMethod) }}</td>
                        </tr>
                        <tr>
                          <th>{{ $t('Button.CancellationQuantity') }}</th>
                          <td class="disflex evidence">
                            <number-counter
                              v-model="temp_qty"
                              :max="orderQty.max"
                              :min="orderQty.min + 1"
                            />
                            <!--                            <div class="flex_right">-->
                            <!--                              <button-->
                            <!--                                class="black_btn width30"-->
                            <!--                                @click="qty = temp_qty"-->
                            <!--                              >-->
                            <!--                                {{ $t('Button.Quantity') }}-->
                            <!--                              </button>-->
                            <!--                            </div>-->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="border_top mt20">
            <h6 class="allPage_tit">{{ $t('CancelList.CancelReason') }}</h6>
            <div class="inner p04">
              <ul class="wrap_flex basic_info cancel_list">
                <li class="disflex input_chk_box">
                  <label class="color37 font2" for="cancel">
                    <input
                      id="cancel"
                      class="reason"
                      name="고객단순변심"
                      type="checkbox"
                      value="고객단순변심"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.SimpleChange') }}
                  </label>
                </li>
                <li class="disflex input_chk_box">
                  <label class="color37 font2">
                    <input
                      class="reason"
                      type="checkbox"
                      value="상품불만족"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.Dissatisfaction') }}
                  </label>
                </li>
                <li class="disflex input_chk_box">
                  <label class="color37 font2">
                    <input
                      class="reason"
                      type="checkbox"
                      value="고객주문오류"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.OrderError') }}
                  </label>
                </li>

                <li class="disflex input_chk_box">
                  <label class="color37 font2">
                    <input
                      class="reason"
                      type="checkbox"
                      value="다른곳보다비쌈"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.Expensive') }}
                  </label>
                </li>
                <li class="disflex input_chk_box">
                  <label class="color37 font2">
                    <input
                      class="reason"
                      type="checkbox"
                      value="쿠폰,할인혜택이 변경됨"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.BenefitChange') }}
                  </label>
                </li>
                <li class="disflex input_chk_box">
                  <label class="color37 font2">
                    <input
                      class="reason"
                      type="checkbox"
                      value="배송지연"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.ShippingDelay') }}
                  </label>
                </li>
                <li class="disflex input_chk_box">
                  <label class="color37 font2">
                    <input
                      class="reason"
                      type="checkbox"
                      value="시스템오류취소"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.SystemError') }}
                  </label>
                </li>

                <li class="disflex input_chk_box">
                  <label class="color37 font2">
                    <input
                      type="checkbox"
                      value="중복주문취소"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.DuplicateOrder') }}
                  </label>
                </li>

                <li class="disflex input_chk_box">
                  <label class="color37 font2">
                    <input
                      v-model="showReasonInput"
                      type="checkbox"
                      value="etc"
                      @click="handleInputCheckBox"
                    />
                    {{ $t('Button.EctEnter') }}
                  </label>
                </li>
                <!-- <li class="ect_reason disnone">
                  <label for="cancel9" class="basic_tit"></label>
                  <input
                    type="text"
                    id="cancel9"
                    class="deep_type"
                    placeholder="기타 상세사유를 입력하세요."
                  />
                </li> -->
              </ul>
            </div>
            <div v-if="showReasonInput == true" class="inner p04">
              <li class="disflex input_chk_box">
                <label class="basic_tit" for="cancel9"></label>
                <input
                  id="cancel9"
                  v-model="etc_value"
                  :placeholder="$t('CancelList.EctDetail')"
                  class="deep_type"
                  type="text"
                />
              </li>
            </div>
          </div>
          <!--환불정보 신용카드 와 가상계좌(무통장입금) 차이있음/시작-->
          <div class="border_top mt20">
            <h6 class="allPage_tit">{{ $t('CancelList.RefundInfo') }}</h6>
            <div class="inner p04">
              <div class="table_wrap ver3">
                <div>
                  <table class="table_box">
                    <colgroup>
                      <col style="width: 27%" />
                      <col style="width: 73%" />
                    </colgroup>

                    <!-- 신용카드일 경우 -->
                    <tbody v-if="item.order.payMethod === 'CARD'">
                      <tr>
                        <th>{{ $t('Button.CancelAmount') }}</th>
                        <td>
                          ₩
                          {{ priceTotal.numberFormat() }}
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('Basket.DeliveryFee') }}</th>
                        <td>
                          ₩ {{ props.item.delivery_price_value.numberFormat() }}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {{ $t('CancelList.DeductionAmount') }}
                        </th>
                        <td>
                          {{
                            props.item.use_point_value
                              ? Number(
                                  props.item.use_point_value * temp_qty
                                ).numberFormat()
                              : '0'
                          }}{{ $t('Button.UsePoint') }} P<br />
                          ₩{{
                            props.item.use_coupon_value
                              ? Number(
                                  props.item.use_coupon_value * temp_qty
                                ).numberFormat()
                              : '0'
                          }}(쿠폰사용)
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('CancelList.RefundInfo') }}</th>
                        <td>
                          <p class="font2">
                            신용카드₩
                            {{
                              (
                                Number(priceTotal) +
                                Number(props.item.delivery_price_value)
                              ).numberFormat()
                            }}<br />
                            포인트
                            {{
                              props.item.use_point
                                ? Number(
                                    props.item.use_point * temp_qty
                                  ).numberFormat()
                                : '0'
                            }}
                            P
                          </p>
                          <p class="font2">
                            <!-- 포인트
                        {{
                          order.use_point
                            ? order.use_point.numberFormat() + 'P'
                            : '0'
                        }} -->
                          </p>
                        </td>
                      </tr>
                      <!--가상계좌입금전 이것만 보이기ㄱ-->
                      <tr class="refund_pri">
                        <th>{{ $t('CancelList.RefundAmount') }}</th>
                        <td>
                          ₩
                          {{
                            (
                              Number(priceTotal) +
                              Number(props.item.delivery_price_value)
                            ).numberFormat()
                          }}
                        </td>
                      </tr>
                    </tbody>

                    <!-- 가상계좌 && 입금전 -->
                    <template v-if="item.order.payMethod === 'VBANK'">
                      <tbody v-if="item.order.status === 'REQUEST'">
                        <tr class="refund_pri">
                          <th>{{ $t('CancelList.RefundAmount') }}</th>
                          <td>₩ 0</td>
                        </tr>
                      </tbody>
                      <tbody v-if="item.order.status === 'PAYMENT'">
                        <tr class="refund_pri">
                          <th>{{ $t('CancelList.RefundAmount') }}</th>
                          <td>
                            ₩
                            {{ priceTotal.numberFormat() }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </table>
                </div>
              </div>
              <ul class="order_tip cancel_tip">
                <template v-if="item.order.payMethod === 'VBANK'">
                  <template v-if="item.order.status === 'PAYMENT'">
                    <li class="agree_block disflex input_chk_box">
                      <input id="refundNum" v-model="agree2" type="checkbox" />
                      <label class="chkbox" for="refundNum">
                        {{ $t('CancelList.RefundDescription1') }}
                      </label>
                    </li>
                    <li>{{ $t('CancelList.RefundDescription2') }}</li>
                    <li>{{ $t('CancelList.RefundDescription3') }}</li>
                    <li>{{ $t('CancelList.RefundDescription4') }}</li>
                  </template>
                </template>
              </ul>
            </div>
          </div>
          <!--환불정보 신용카드 와 가상계좌(무통장입금) 차이있음/끝-->
          <!--환불계좌 무통장입금일때만 뜨기-->
          <div
            v-if="
              item.order.payMethod === 'VBANK' &&
              item.order.status === 'PAYMENT'
            "
            class="border_top mt30"
          >
            <h6 class="allPage_tit">
              {{ $t('CancelList.RefundDescription5') }}
            </h6>
            <div class="inner refund_number">
              <ul class="wrap_flex basic_info">
                <li class="mt20">
                  <h6 class="basic_tit">
                    {{ $t('CancelList.RefundDescription6') }}
                  </h6>
                  <div class="option_box">
                    <bank-selector @input="(val) => (bank = val)" />
                  </div>
                </li>

                <li>
                  <label class="basic_tit ver2" for="">{{
                    $t('CancelList.AccountName')
                  }}</label>
                  <input
                    v-model="bank_name"
                    class="deep_type"
                    readonly
                    type="text"
                  />
                </li>
                <li>
                  <label class="basic_tit ver2" for="">{{
                    $t('CancelList.Account')
                  }}</label>
                  <input
                    v-model="bank_num"
                    :placeholder="$t('CancelList.AlertAccount')"
                    class="deep_type"
                    type="text"
                  />
                </li>
                <li>
                  <p
                    class="colora8 font2"
                    v-html="$t('CancelList.RefundDescription7')"
                  ></p>
                </li>
              </ul>
            </div>
          </div>
          <!--환불정보 신용카드 와 가상계좌(무통장입금) 차이있음/끝-->
          <div class="border_top mt30">
            <h6 class="allPage_tit">
              {{ $t('CancelList.RefundDescription8') }}
            </h6>
            <div class="inner">
              <ul class="order_tip cancel_tip">
                <li class="agree_block align_top input_chk_box">
                  <input id="essential" v-model="agree" type="checkbox" />
                  <label class="chkbox" for="essential">
                    {{ $t('CancelList.RefundDescription9') }}
                  </label>
                </li>
                <li>
                  {{ $t('CancelList.RefundDescription10') }}
                </li>
                <li>
                  {{ $t('CancelList.RefundDescription11') }}
                </li>
                <li>{{ $t('CancelList.RefundDescription12') }}</li>
                <li>
                  {{ $t('CancelList.RefundDescription13') }}
                </li>
                <li>
                  {{ $t('CancelList.RefundDescription14') }}
                </li>
                <li>
                  {{ $t('CancelList.RefundDescription15') }}
                </li>
                <li>
                  {{ $t('CancelList.RefundDescription16') }}
                </li>
                <li>
                  {{ $t('CancelList.RefundDescription17') }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <template>
        <div slot="footer" class="pop_foot">
          <button type="button" @click="$emit('close')">
            {{ $t('CancelList.PrevPage') }}
          </button>
          <button class="btn_pop_open" type="button" @click="handleClickSubmit">
            {{ $t('CustomerService.OrderCancel') }}
          </button>
        </div>
      </template>
    </down-full-up-popup>

    <!--    <order-cancel-ok v-if="isShowOkCancel" @close="handleCloseCancelOk" />-->
  </div>
</template>

<script>
import { checkPlatform } from '../../../../utils/Buy';
import DownFullUpPopup from '../../../popups/DownFullUpPopup.vue';
import OrderStatus from '../../../../consts/OrderStatus';
import ImageBgSrc from '../../ImageBlock/ImageBgSrc.vue';
import NumberCounter from '../../../Inputs/NumberCounter/NumberCounter.vue';
import { computed, reactive, toRefs, watch } from 'vue-demi';
import useAlert from '../../../../composables/dialogs/useAlert';
import useConfirm from '../../../../composables/dialogs/useConfirm';
// import OrderCancelOk from './OrderCancelOk.vue';
import {
  lockBodyClassnameHidden,
  removeBodyOverflowHidden,
  unlockBodyClassnameHidden,
} from '../../../../utils/CommonUtils';
import PaymentAPI from '@/apis/PaymentAPI';
import OrderAPI from '@/apis/OrderAPI';
import BankSelector from '../../../Inputs/BankSelector/BankSelector.vue';
import useUserInfo from '@Composables/user/useUserInfo';

export default {
  props: ['order', 'item'],
  components: {
    BankSelector,

    DownFullUpPopup,
    ImageBgSrc,
    NumberCounter,
    // OrderCancelOk,
  },
  setup(props, context) {
    const { showAlert } = useAlert();
    const { showConfirm } = useConfirm();
    const { info } = useUserInfo();
    const $ = window.$;

    const state = reactive({
      temp_qty: 1,
      qty: props.item.qty,
      bank: null,
      bank_num: '',
      bank_name: info?.mem_username,
      etc_value: '',
      cancelValue: '',
      agree: false,
      agree2: false,
      isShowOkCancel: false,
      showReasonInput: false,
      isQtyCheck: false,
      orderQty: {
        min: 0,
        max: 0,
      },
      order_qty: 0,
    });
    console.log('^^^^^^^^');
    console.log('props.item', props.item);
    console.log('props.order', props.order);
    console.log('^^^^^^^^');

    const cancelCheck = async () => {
      console.log(props.order.provider[props.item.den_id].items[0].cod_id);
      const { data } = await OrderAPI.checkOrderDetailQty({
        cod_id: props.item.cod_id,
      });

      state.orderQty.min = data.data.cancel_qty;
      state.orderQty.max = data.data.possible_qty;
      state.order_qty = data.data.order_qty;
    };

    watch(
      () => state.temp_qty,
      (newValue) => {
        state.temp_qty = newValue;
        console.log(state.temp_qty);
      }
    );
    const totalDiscount = computed(() => {
      return (
        Number(props.item.use_coupon_value * state.temp_qty) +
        Number(props.item.use_grade_value * state.temp_qty) +
        Number(props.item.use_point_value * state.temp_qty) +
        Number(props.item.use_coupon_gap)
      );
    });
    watch(
      () => totalDiscount,
      (newValue) => {
        totalDiscount.value = newValue;
      }
    );
    console.log(totalDiscount);
    const priceTotal = computed(() => {
      const values =
        Number(props.item.cit_sale_price) * state.temp_qty -
        Number(totalDiscount.value);

      return values;
    });

    console.log(priceTotal.value);
    console.log(state.temp_qty);
    cancelCheck();
    const canCelPayMent = async (provider, cancellation) => {
      console.log(context.refs.order_cancel);
      console.log(provider.items.length);
      await PaymentAPI.canCelPayMent({
        mem_id: localStorage.getItem('mem_id'),
        order_id: provider.items[0].order_id,
        cod_id: provider.items[0].cod_id,
        // cancel_ea: provider.items.length ,
        cancel_ea: state.temp_qty,
        cancel_reason: state.cancelValue,
        cancel_memo: cancellation,
        receive_bank_user: state.bank_name,
        receive_bankname: state.bank,
        receive_bank_account: state.bank_num,
      }).then((res) => {
        console.log(res);
        showConfirm(res.data.ResultMsg, () => {
          if (res.data.ResultCode === '2001') {
            state.isShowOkCancel = true;
            showAlert(context.root.$t('CancelList.ResultCancel'));
            context.root.$router.push('/mypage');
          }
        });
      });
    };

    lockBodyClassnameHidden();

    return {
      ...toRefs(state),
      props,
      OrderStatus,
      totalDiscount,
      priceTotal,
      provider: props.order.provider[props.item.den_id],
      device_type: checkPlatform(window.navigator.userAgent),
      getPayMethodName(type) {
        switch (type) {
          case 'KAKAO':
            return context.root.$t('OrderInquiry.KakaoPay');
          case 'NAVER':
            return context.root.$t('OrderInquiry.NaverPay');
          case 'CARD':
            return context.root.$t('OrderInquiry.Card');
          case 'VBANK':
            return context.root.$t('OrderInquiry.VirtualAccount');
          case 'BANK':
            return context.root.$t('OrderInquiry.BankTransfer');
          default:
            return '';
        }
      },
      handleInputCheckBox(target) {
        $('.cancel_list input[type="checkbox"]').each(function (e, ele) {
          ele.checked = false;
        });
        target.target.checked = true;
        if (target.target.value !== 'etc') {
          state.showReasonInput = false;
        }
        if (target.target.value !== 'etc') {
          state.cancelValue = target.target.value;
        } else {
          state.cancelValue = 'etc';
        }
      },

      handleClickSubmit() {
        if (
          props.item.order.payMethod === 'VBANK' &&
          props.item.order.status === 'PAYMENT'
        ) {
          if (state.agree2 === false) {
            showAlert(context.root.$t('CancelList.AccountAgree'));
            return;
          }

          if (state.bank === null) {
            showAlert(context.root.$t('Input.BankSelect'));
            return false;
          }
          if (state.bank_name === '') {
            showAlert(context.root.$t('CancelList.AlertName'));
            return false;
          }
          if (state.bank_num === '') {
            showAlert(context.root.$t('CancelList.AlertAccount'));
            return false;
          }
        }

        if (state.agree === false) {
          showAlert(context.root.$t('CancelList.AlertAgree'));
          return;
        }
        console.log(state.cancelValue, state.etc_value);

        showConfirm(context.root.$t('CancelList.AlertConfirm'), () => {
          canCelPayMent(
            props.order.provider[props.item.den_id],
            state.cancelValue === 'etc' ? state.etc_value : state.cancelValue
          );
          //context.root.$router.push('/mypage/status-change-order');
        });
      },
      handleCloseCancelOk() {
        state.isShowOkCancel = false;
        context.root.$router.push('/mypage/status-change-order');
      },
    };
  },
  destroyed() {
    unlockBodyClassnameHidden();
    removeBodyOverflowHidden();
  },
};
</script>

<style>
.order-cancel .inner {
  padding: 0;
}
.order-cancel .inner.refund_number {
  padding: 0 14px;
}
</style>
