<template>
  <div class="group_list">
    <div class="pro_desc_box type4 disflex">
      <template v-if="isLink">
        <router-link :to="`/brand/detail/${item.den_id}`">
          <image-bg-src
            :src="item.img_url ? item.img_url : item.seller_picture"
            class="profile_img"
          />
          <div class="profile_user new_title">
            <h3 class="name fontEn">{{ item.den_name }}</h3>
            <p class="desc">{{ item.provider_summary }}</p>
          </div>
        </router-link>

        <!--pc에서 판매자정보버튼 출력ㄱ-->
        <template v-if="device_type === 'pc'">
          <button
            class="radi_btnG flex_right"
            type="button"
            @click="isShowBrandDetail = true"
          >
            {{ $t("Button.SellerInfo") }}
          </button>
        </template>
        <template v-else>
          <a :href="`tel:${item.cs_no}`" class="radi_btnG flex_right">
            {{ $t("Button.MakeAPhone") }}
          </a>
        </template>
      </template>
      <template v-else>
        <image-bg-src :src="item.seller_picture" class="profile_img" />
        <div class="profile_user new_title" style="padding: 4px 50px">
          <h3 class="name fontEn">{{ item.den_name }}</h3>
          <p class="desc">{{ item.provider_summary }}</p>
        </div>
      </template>
    </div>

    <order-item-product
      v-for="product in item.items"
      :key="product.cod_id"
      :isLink="isLink"
      :isSelect="isSelect"
      :isShowButton="isShowButton"
      :item="product"
      :order="order"
      :type="type"
      @UpdateOrderItem="handleUpdateOrderItem"
      @change:checked="handleChangeChecked"
    />
    <provider-info
      v-if="isShowBrandDetail"
      :den_id="item.den_id"
      @close="isShowBrandDetail = false"
    />
  </div>
</template>

<script>
import { checkPlatform } from "@/utils/Buy";
import ImageBgSrc from "../ImageBlock/ImageBgSrc.vue";
import OrderItemProduct from "./OrderItemProduct.vue";
import ProviderInfo from "./popups/ProviderInfo.vue";
import { reactive, toRefs } from "vue-demi";
import moment from "moment";

export default {
  computed: {
    moment() {
      return moment;
    },
  },
  components: { ImageBgSrc, OrderItemProduct, ProviderInfo },
  props: ["item", "order", "type", "isShowButton", "isSelect", "isLink"],
  setup(props, context) {
    const state = reactive({
      isShowBrandDetail: false,
    });
    console.log("&&&&&&&&&&");
    console.log(props.item.items);
    console.log(props.item);
    console.log("#$$$$$$$$$$$$$#######");
    console.log(props.order);
    console.log("&&&&&&&&&&");
    return {
      ...toRefs(state),
      device_type: checkPlatform(window.navigator.userAgent),
      handleUpdateOrderItem() {
        context.emit("orderItemUpdate", true);
      },
      handleChangeChecked(checked, product) {
        context.emit("change:checked", checked, product);
      },
    };
  },
};
</script>

<style></style>
