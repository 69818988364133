<template>
  <input
    ref="inputRef"
    v-model="text"
    type="text"
    v-bind="$attrs"
    :disabled="disable"
    @click="$emit('click')"
    @input="handleInput"
    @focusout="handleFocusOut"
    @focus="handleFocus"
  />
</template>

<script>
import { ref, watch } from 'vue-demi';
export default {
  props: ['maxLength', 'value', 'max', 'validate'],
  setup(props, context) {
    const text = ref(props.value ?? '');
    const inputRef = ref(null);
    const disable = ref(!!props.validate);
    watch(
      () => props.value,
      () => {
        text.value = props.value;
        context.emit('input', props.value);
      }
    );
    return {
      text,
      inputRef,
      disable,
      handleInput() {
        let value = text.value.replace(/[\D]/g, '');

        if (props.maxLength) {
          value = value.substr(0, props.maxLength);
        }

        if (props.max && Number(value) > props.max) {
          value = props.max;
        }

        text.value = Number(value);

        context.emit('input', text.value);
      },
      handleFocusOut(e) {
        context.emit('focusout', e);
      },
      handleFocus(e) {
        context.emit('focusIn', e);
      },
    };
  },
};
</script>

<style></style>
