import { axios } from "@/plugins/axios";

export default {
  paymentRequest(params) {
    return axios.post("payment/payment_request", params);
  },
  getSignData(params) {
    return axios.post("payment/getSignData", params);
  },
  canCelPayMent(params) {
    return axios.post("payment/payment_request_cancel", params);
  },
  calculateData(params) {
    return axios.post("payment/calculate", params);
  },
  getPaymentRequestCancel(params) {
    return axios.post("payment/getPaymentRequestCancel", params);
  },
};
