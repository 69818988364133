<template>
  <div class="drop_box">
    <button type="button" class="tit" @click="handleClick">
      <span>
        <div v-if="isTag == true">
          <em v-if="isAnser === 'n'" class="tag gray mb6">{{
            $t('CustomerService.AnswerWait')
          }}</em>
          <em v-else class="tag gray mb6">{{
            $t('CustomerService.AnswerComplete')
          }}</em>
        </div>
        <strong class="txthide2">
          {{ item.cqa_title }}
        </strong>
        <em class="fon12gray imp">
          <slot>{{ item.date }}</slot>
        </em>
      </span>
    </button>
    <div ref="contentRef" :class="`answer_box ${open ? 'on' : ''}`">
      <div>
        <!-- <div class="bggray_box" v-html="item.answer" v-if="item.answer" /> -->
        <div
          v-if="item.gray_content"
          class="bggray_box"
          v-html="item.gray_content"
        ></div>
        <p v-html="item.cqa_content"></p>
        <div class="image_wrap">
          <div
            v-for="(url, idx) in imageUrl"
            :key="idx"
            style="max-width: 20%; margin-left: 10px"
          >
            <img v-if="url" :src="url" alt="" />
          </div>
        </div>
        <div v-if="isAnser === 'y'" :id="`${item.cqa_answer_mem_id}`">
          <div v-text="item.cqa_answer_title" />
          <div class="bggray_box" v-html="item.cqa_answer_content" />
        </div>

        <div v-if="isEdit" class="btm_foot mt14">
          <button
            type="button"
            class="btn_del_tos btn_pop_open"
            :class="`${isAnser !== 'n' ? 'on' : ''}`"
            @click="handleDelete(item.cqa_id)"
          >
            {{ $t('Button.Delete') }}
          </button>
          <button
            v-if="isAnser === 'n'"
            type="button"
            class="btn_pop_open"
            @click="handleEditClick"
          >
            {{ $t('Button.Edit') }}
          </button>
        </div>
      </div>
    </div>
    <InquiryFormPopup
      v-if="isShowForm"
      :edit="item"
      @close="handleCLose"
      @update="hadleEditSubmit"
    />
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue-demi';
import InquiryFormPopup from '@Views/service-centers/popups/InquiryFormPopup';
import ServiceAPI from '@/apis/ServiceAPI';
import useUserInfo from '@Composables/user/useUserInfo';
import useConfirm from '@Composables/dialogs/useConfirm';
import useToast from '@Composables/dialogs/useToast';

export default {
  components: { InquiryFormPopup },
  props: {
    isAnser: { default: false },
    isEdit: { default: false },
    isTag: { default: false },
    isOpen: { default: false },
    item: { default: {} },
    isUpdate: { default: false },
    item_url: { default: null },
  },
  setup(props, context) {
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();
    const $ = window.$;
    const state = reactive({
      isShowForm: false,
      imageUrl: [],
      keys: null,
    });
    const open = ref(props.isOpen);
    const contentRef = ref(null);
    watch(
      () => props.isOpen,
      nowVal => {
        if (nowVal === true) {
          open.value = true;
          $(contentRef.value).stop().slideDown(300);
        }
      },
    );
    const { info } = useUserInfo();
    state.imageUrl.push(props.item.cqa_file1);
    state.imageUrl.push(props.item.cqa_file2);
    state.imageUrl.push(props.item.cqa_file3);

    state.imageUrl = state.imageUrl.map(imageUrl => {
      if (imageUrl !== '') {
        return process.env.VUE_APP_FILE_URL + imageUrl;
      }
    });

    watch(
      () => props.item,
      () => {
        state.imageUrl = [];
        state.imageUrl.push(props.item.cqa_file1);
        state.imageUrl.push(props.item.cqa_file2);
        state.imageUrl.push(props.item.cqa_file3);

        state.imageUrl = state.imageUrl.map(imageUrl => {
          if (imageUrl !== '') {
            return process.env.VUE_APP_FILE_URL + imageUrl;
          }
        });
      },
    );
    return {
      open,
      contentRef,
      ...toRefs(state),
      handleClick() {
        open.value = !open.value;
        if ($('.drop_box .answer_box').hasClass('on')) {
          $('.drop_box').removeClass('on');
        }
        if ($(contentRef.value).hasClass('on')) {
          $(contentRef.value).removeClass('on');
          $(contentRef.value).parents('.drop_box').removeClass('on');
          $(contentRef.value).stop().slideUp(300);
        } else {
          $(contentRef.value).stop().slideDown(300);
          $(contentRef.value).parents('.drop_box').addClass('on');
          $(contentRef.value).addClass('on');
        }
        $('.answer_box').not(contentRef.value).slideUp(300);
        $('.answer_box').not(contentRef.value).removeClass('on');
      },
      handleCLose() {
        state.isShowForm = false;
      },
      async hadleEditSubmit(updateData) {
        const { data } = await ServiceAPI.updateInquiryList(updateData);
        console.log(data);
        context.emit('isUpdate');
        state.isShowForm = false;
      },
      handleEditClick() {
        state.isShowForm = true;
      },
      async handleDelete(getId) {
        showConfirm(context.root.$t('Basket.AlertDelete'), async () => {
          const { data } = await ServiceAPI.deleteInquiry({
            mem_id: info?.mem_id,
            cqa_id: getId,
          });
          if (data.result) {
            $('.answer_box').slideUp();
            $('.answer_box').removeClass('on');
            context.emit('isUpdate');
            addMsg(context.root.$t('Button.AlertDeleteComplete'));
          }
        });
      },
    };
  },
};
</script>

<style>
.notice-content * {
  font-size: 12px !important;
  font-family: 'Noto Sans KR', 'sans-serif' !important;
}
.btn_del_tos.btn_pop_open.on {
  width: 100%;
}
.drop_box p,
.drop_box ul,
.drop_box li,
.drop_box div,
.drop_box span {
  font-size: 12px !important;
  color: #202020 !important;
  font-family: 'Noto Sans KR', 'sans-serif' !important;
  letter-spacing: -0.24px !important;
}

.drop_box .tit {
  background: url('../../../assets/images/design/bottom-icon.svg') no-repeat
    right 14px center;
}
.drop_box.on .tit {
  background: url('../../../assets/images/design/arrow-up-icon.svg') no-repeat
    right 14px center;
}
.image_wrap {
  margin-top: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.image_wrap img:nth-child(1) {
  margin-left: 0;
}
.image_wrap img[src=''] {
  display: none;
}
.fon12gray.imp .cqa_dtm {
  font-size: 12px !important;
  color: #a8a8a8 !important;
}
</style>
