export const cancelStatus = [
  "CANCEL_REQUEST",
  "CANCEL",
  "CANCEL_COMPLETE",
  "PAYMENT",
];
import { i18n } from "@/plugins/i18n/i18n";

export const changeStatus = [
  "CHANGE_REQUEST",
  "CHANGE_ING",
  "CHANGE_CANCEL",
  "CHANGE_DENY",
  "CHANGE_COMPLETE",
  "CHANGE_DELIVERY_COMPLETE",
  "CHANGE_DELIVERY_ING",
  "CHANGE_DELIVERY_READY",
];

export const returnStatus = [
  "RETURN_REQUEST",
  "RETURN_PICKUP",
  "RETURN_ING",
  "RETURN_ING_COMPLETE",
  "RETURN_HOLD",
  "RETURN_ENTER",
  "RETURN_CHECK",
  "RETURN_DENY",
  "RETURN_ING",
  "RETURN_COMPLETE",
  "REFUND_REQUEST",
  "REFUND_APPROVAL",
  "RETURN_CANCEL",
  "REFUND_CONFIRM",
  "REFUND_ERROR",
  "REFUND_COMPLETE",
];

export const deliveryStatus = [
  "DELIVERY_READY",
  "DELIVERY_DELAY",
  "DELIVERY_ORDER",
  "DELIVERY",
  "DELIVERY_COMPLETE",
  "DELIVERY_DELAY_REASON",
  "DELIVERY_DELAY_HOLD",
];

export default {
  REQUEST: i18n.t("OrderState.Before"),
  PAYMENT: i18n.t("OrderState.Payment"),

  DELIVERY_READY: i18n.t("OrderState.ReadyShipping"),
  DELIVERY_DELAY: i18n.t("OrderState.DelayShipping"),
  DELIVERY_ORDER: i18n.t("OrderState.ReadyShipping"),
  DELIVERY_DELAY_REASON: i18n.t("OrderState.DelayShippingNotice"),
  DELIVERY_DELAY_HOLD: i18n.t("OrderState.ShippingDelayHold"),
  DELIVERY: i18n.t("OrderState.Shipping"),
  DELIVERY_COMPLETE: i18n.t("OrderState.ShippingComplete"),
  COMPLETE: i18n.t("OrderState.Confirmation"),
  COMPLETE_CANCEL: i18n.t("OrderState.CompleteCancel"),

  CANCEL_REQUEST: i18n.t("OrderState.OrderCancelRequest"),
  CANCEL: i18n.t("OrderState.OrderCancel"),
  CANCEL_COMPLETE: i18n.t("OrderState.OrderCancelComplete"),
  CANCEL_CANCEL: i18n.t("OrderState.CancelRequestCancel"),

  CHANGE_REQUEST: i18n.t("OrderState.Exchange"),
  CHANGE_ING: i18n.t("OrderState.ExchangeProgress"),
  CHANGE_CANCEL: i18n.t("OrderState.ExchangeCancel"),
  CHANGE_DENY: i18n.t("OrderState.ExchangeDeny"),
  CHANGE_COMPLETE: i18n.t("OrderState.ExchangeComplete"),
  CHANGE_DELIVERY_ING: i18n.t("OrderState.ExchangeIng"),
  CHANGE_DELIVERY_DELAY_REASON: i18n.t("OrderState.ExchangeDelayInformation"),
  CHANGE_DELIVERY_COMPLETE: i18n.t("OrderState.ExchangeShipping"),
  CHANGE_DELIVERY_READY: i18n.t("OrderState.ExchangeReady"),

  RETURN_REQUEST: i18n.t("OrderState.Return"),
  RETURN_PICKUP: i18n.t("OrderState.ReturnPickup"),
  RETURN_ING: i18n.t("OrderState.ReturnsAreBeingCollected"),
  RETURN_ING_COMPLETE: i18n.t("OrderState.ReturnCollectionCompleted"),
  RETURN_ENTER: i18n.t("OrderState.ReturnProgress"),
  RETURN_CHECK: i18n.t("OrderState.ReturnCheck"),
  RETURN_COMPLETE: i18n.t("OrderState.ReturnComplete"),
  RETURN_CANCEL: i18n.t("OrderState.ReturnCancel"),
  RETURN_DENY: i18n.t("OrderState.ReturnDeny"),
  RETURN_HOLD: i18n.t("OrderState.ReturnPending"),

  REFUND_REQUEST: i18n.t("OrderState.RefundRequest"),
  REFUND_APPROVAL: i18n.t("OrderState.RefundApproval"),
  REFUND_ACANCEL: i18n.t("OrderState.RefundCancel"),
  REFUND_CONFIRM: i18n.t("OrderState.RefundConfirm"),
  REFUND_ERROR: i18n.t("OrderState.RefundError"),
  REFUND_COMPLETE: i18n.t("OrderState.RefundComplete"),
};
