<template>
  <down-full-up-popup
    :title="$t('CustomerService.OrderLookUp')"
    class="my-order-list-popup"
    @close="$emit('close')"
  >
    <div slot="body">
      <period-search v-model="date" @change="handleChangeGigan" />

      <order-item
        v-for="item in items"
        :key="item.order_id"
        :isLink="false"
        :isSelect="true"
        :isShowButton="false"
        :isShowOrder="true"
        :item="item"
        type="detail"
        @change:checked="handleChangeChecked"
      />
      <button class="more" type="button" @click="handleClickMore">
        {{ $t("Ect.More") }}
      </button>
    </div>
    <template slot="footer">
      <button
        class="black_btn font4 pos_fixed0 btn_pop_open"
        style="width: 100%"
        type="button"
        @click="handleClickSubmit"
      >
        {{ $t("Review.Registration") }}
      </button>
    </template>
  </down-full-up-popup>
</template>

<script>
import OrderAPI from "@/apis/OrderAPI";
import { reactive, toRefs } from "vue-demi";
import PeriodSearch from "../../../components/Inputs/PeriodSearch/PeriodSearch.vue";
import DownFullUpPopup from "../../../components/popups/DownFullUpPopup.vue";
import OrderItem from "../../../components/ui/OrderItem/OrderItem.vue";
import DateUtils from "@/utils/DateUtils";
import useUserInfo from "@/composables/user/useUserInfo";
import useToast from "../../../composables/dialogs/useToast";

export default {
  components: { DownFullUpPopup, PeriodSearch, OrderItem },

  setup(_, context) {
    const { info } = useUserInfo();
    const { addMsg } = useToast();

    const state = reactive({
      date: [],
      total: 0,
      orders: [],
      hasMore: true,
      items: [],
      inquiryItems: false,
    });

    let selected = null;

    const params = {
      mem_id: info?.mem_id,
      page: 0,
      limit: 10,
    };

    const loadOrderItems = async () => {
      const { data } = await OrderAPI.getMyOrderItems(params);

      state.total = data.total;
      state.orders = data.orders;

      groupByOrder(data.result_data);

      state.hasMore = state.total > state.items.length && !params.status;
    };

    const groupByOrder = (orders) => {
      const items = {};

      orders.forEach((item) => {
        item.order = state.orders.find(
          (order) => order.order_id === item.order_id
        );

        if (params.status !== undefined && params.status !== item.status) {
          return;
        }

        // 주문
        if (items[item.order_id] === undefined) {
          items[item.order_id] = {
            order_id: item.order_id,
            ins_dtm: item.ins_dtm,
            use_point: item.order.use_point,
            delivery_price: item.order.delivery_price,
            provider: {},
          };
        }

        const order = items[item.order_id];

        // 브랜드
        if (order["provider"][item.den_id] === undefined) {
          order["provider"][item.den_id] = {
            den_id: item.den_id,
            den_name: item.den_name,
            seller_picture: item.seller_picture,
            img_url: item.img_url,
            cs_no: item.cs_no,
            items: [],
          };
        }

        order["provider"][item.den_id].items.push(item);
      });

      const groupItems = [];

      Object.keys(items).forEach((key) => {
        groupItems.push(items[key]);
      });

      groupItems.sort((a, b) => {
        const createDateA = DateUtils.dttmToNumber(a.ins_dtm);
        const createDateB = DateUtils.dttmToNumber(b.ins_dtm);

        return createDateB - createDateA;
      });

      if (params.page == 0) {
        state.items = [];
      }

      state.items = [...state.items, ...groupItems];
    };
    console.log(state.items, "<----state.items");
    return {
      ...toRefs(state),

      handleChangeGigan(data) {
        params.page = 0;

        params.startDate = data[0];
        params.endDate = data[1];

        loadOrderItems();
      },
      handleClickMore() {
        params.page++;

        loadOrderItems();
      },
      handleChangeChecked(checked, product) {
        const targetCodId = product.cod_id;

        selected = checked ? product : null;

        if (checked) {
          document.querySelectorAll("input[data-cod_id]").forEach((node) => {
            const cod_id = node.getAttribute("data-cod_id");
            const isTarget = targetCodId === cod_id;

            node.checked = isTarget;
          });
        }
      },
      handleClickSubmit() {
        if (selected === null) {
          addMsg(context.root.$t("CustomerService.AlertSelect"));
        } else {
          context.emit("confirm", selected);
        }
      },
    };
  },
};
</script>

<style scoped>
.my-order-list-popup ::v-deep.pop_body > .inner {
  padding: 0;
}
.my-order-list-popup ::v-deep.prod_img .image-bg-src {
  border-radius: 0;
}
.my-order-list-popup .look_layput_pop .pro_desc_box {
  width: 100%;
}

.more {
  border: 1px solid #ccc;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
</style>
