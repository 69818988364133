export function checkPlatform(ua) {
  if (ua === undefined) {
    ua = window.navigator.userAgent;
  }

  ua = ua.toLowerCase();
  var platform = {};
  var matched = {};
  var userPlatform = 'PC';
  var platform_match =
    /(ipad)/.exec(ua) ||
    /(ipod)/.exec(ua) ||
    /(windows phone)/.exec(ua) ||
    /(iphone)/.exec(ua) ||
    /(kindle)/.exec(ua) ||
    /(silk)/.exec(ua) ||
    /(android)/.exec(ua) ||
    /(win)/.exec(ua) ||
    /(mac)/.exec(ua) ||
    /(linux)/.exec(ua) ||
    /(cros)/.exec(ua) ||
    /(playbook)/.exec(ua) ||
    /(bb)/.exec(ua) ||
    /(blackberry)/.exec(ua) ||
    [];

  matched.platform = platform_match[0] || '';

  if (matched.platform) {
    platform[matched.platform] = true;
  }

  if (
    platform.android ||
    platform.bb ||
    platform.blackberry ||
    platform.ipad ||
    platform.iphone ||
    platform.ipod ||
    platform.kindle ||
    platform.playbook ||
    platform.silk ||
    platform['windows phone']
  ) {
    userPlatform = 'mobile';
  }

  if (platform.cros || platform.mac || platform.linux || platform.win) {
    userPlatform = 'pc';
  }

  return userPlatform;
}

export function nicepayStart() {
  const payForm = document.payForm;

  if (checkPlatform(window.navigator.userAgent) == 'mobile') {
    //모바일 결제창 진입
    payForm.action = 'https://web.nicepay.co.kr/v3/v3Payment.jsp';
    payForm.acceptCharset = 'euc-kr';
    payForm.submit();
  } else {
    //PC 결제창 진입
    window.goPay(payForm);
  }
}
